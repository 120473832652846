<template>
  <div class="z-30 text-gray-600">
    <div>
      <div class="relative">
        <input v-model="date" type="hidden" />
        <div class="flex flex-row space-x-3">
          <button
            type="button"
            class="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-gray-200"
            @click="!disabled && subtractDay()"
          >
            <svg
              class="inline-flex h-5 w-5 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <div
            class="flex w-44 cursor-pointer flex-row border-b-2 pb-2"
            :class="{
              'border-gray-300 text-blue-900': show,
              'cursor-not-allowed opacity-50': disabled,
              'hover:border-gray-300 hover:text-blue-900': !disabled,
            }"
            @click="!disabled && toggleShow()"
          >
            <span
              :key="text"
              type="text"
              class="col-span-5 w-full cursor-pointer leading-none focus:outline-none"
              :class="{ 'cursor-not-allowed': disabled }"
            >
              {{ text }}
            </span>
            <font-awesome-icon
              data-cy="display-datepicker"
              class="col-start-6 h-4 w-4"
              icon="calendar-alt"
            />
          </div>
          <button
            type="button"
            class="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out"
            :class="{
              'cursor-not-allowed opacity-50': todayIsSelected,
              'hover:bg-gray-200': !disabled && !todayIsSelected,
              'cursor-not-allowed': disabled,
            }"
            @click="!disabled && addDay()"
          >
            <svg
              class="inline-flex h-5 w-5 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
        <div
          v-show="show"
          class="absolute left-0 top-0 mt-10 w-72 rounded-lg bg-white p-4 shadow"
        >
          <div class="mb-2 flex items-center justify-between space-x-10">
            <div>
              <span class="font-bold text-gray-800">
                {{ monthName }}
              </span>
              <span class="ml-1 font-normal text-gray-600">
                {{ year }}
              </span>
            </div>
            <div>
              <button
                type="button"
                class="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-gray-200"
                @click="subtractMonth"
              >
                <svg
                  class="inline-flex h-5 w-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                type="button"
                class="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-gray-200"
                @click="addMonth"
              >
                <svg
                  class="inline-flex h-5 w-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="-mx-1 mb-3 flex flex-wrap">
            <div
              v-for="dayOfWeek in daysOfWeek"
              :key="dayOfWeek"
              class="w-1/7 px-1"
            >
              <div class="text-center text-xs font-medium text-gray-800">
                {{ dayOfWeek }}
              </div>
            </div>
          </div>
          <div class="-mx-1 flex flex-wrap">
            <div
              v-for="day in blankDaysRange"
              :key="day"
              class="w-1/7 p-1 text-center text-sm"
            />
            <div
              v-for="day in daysRange"
              :key="day"
              class="w-1/7 cursor-default rounded-full p-1 text-center text-sm"
              :class="{
                'bg-blue-500 text-white': isSelected(day),
                'ring-1': isToday(day),
                'cursor-pointer hover:bg-blue-200':
                  !isSelected(day) && !isInFuture(day),
                'cursor-not-allowed opacity-50': isInFuture(day),
              }"
              @click="() => selectDate(day)"
            >
              {{ day }}
            </div>
          </div>
          <div>
            <button
              data-cy="today-button"
              class="text-blue-500 hover:text-blue-900"
              @click="selectToday"
            >
              Hoy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listenerMixin, showMixin } from "../../mixins";
import _ from "lodash";
import moment from "moment";

export default {
  name: "DatePicker",
  mixins: [listenerMixin, showMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      daysOfWeek: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
      date: undefined,
    };
  },

  computed: {
    todayIsSelected() {
      const now = moment();
      return this.selectedDate && now.isSame(this.selectedDate, "day");
    },
    selectedDate() {
      return this.$store.state.selectedDate;
    },
    year() {
      return this.date.year();
    },
    month() {
      return this.date.month();
    },
    blankDaysRange() {
      return _.range(1, this.date.startOf("month").day() + 1);
    },
    daysRange() {
      return _.range(1, this.date.daysInMonth() + 1);
    },
    text() {
      return this.selectedDate.calendar(null, {
        lastDay: "[Ayer] (DD/MM)",
        sameDay: "[Hoy] (DD/MM)",
        nextDay: "[Mañana] (DD/MM)",
        lastWeek: "ll",
        nextWeek: "ll",
        sameElse: "ll",
      });
    },
    monthName() {
      const formatedMonth = this.date.format("MMMM");
      return _.startCase(formatedMonth);
    },
  },

  watch: {
    disabled: {
      deep: true,
      handler: "close",
    },
    selectedDate: {
      deep: true,
      handler() {
        this.date = this.selectedDate.clone();
      },
    },
  },
  async created() {
    this.date = this.selectedDate.clone();
  },

  methods: {
    keyDownHandler(e) {
      if (e.key === "Escape") this.show = false;
    },
    getDate(day) {
      return moment(new Date(this.year, this.month, day));
    },
    isSelected(day) {
      const date = this.getDate(day);
      return date.isSame(this.selectedDate, "day");
    },
    isToday(day) {
      const date = this.getDate(day);
      const now = moment();
      return date.isSame(now, "day");
    },
    isInFuture(day) {
      const now = moment();
      const date = this.getDate(day);
      return now < date;
    },
    subtractYear() {
      this.date = this.date.clone().subtract(1, "year");
    },
    subtractMonth() {
      this.date = this.date.clone().subtract(1, "month");
    },
    subtractDay() {
      this.selectDate(this.selectedDate.date() - 1);
      if (this.selectedDate.month() !== this.month) {
        this.subtractMonth();
      }
      if (this.selectedDate.year() !== this.year) {
        this.subtractYear();
      }
    },
    addYear() {
      this.date = this.date.clone().add(1, "month");
    },
    addMonth() {
      this.date = this.date.clone().add(1, "month");
    },
    addDay() {
      this.selectDate(this.selectedDate.date() + 1);
      if (this.selectedDate.month() !== this.month) {
        this.addMonth();
      }
      if (this.selectedDate.year() !== this.year) {
        this.addYear();
      }
    },
    updateSensorData(fromDate) {
      this.$store.commit("setSelectedDate", fromDate);
    },
    selectToday() {
      this.date = moment();
      this.selectDate(this.date.date());
    },
    selectDate(day) {
      if (!this.isInFuture(day) && !this.isSelected(day)) {
        const fromDate = this.getDate(day);
        fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        this.close();
        this.updateSensorData(fromDate);
      }
    },
  },
};
</script>
