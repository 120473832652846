<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h2"
                    class="text-xl font-semibold leading-6 text-gray-900"
                  >
                    {{
                      isMaintenanceActive
                        ? "Modo mantención activo"
                        : "Duración modo mantención"
                    }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      {{
                        isMaintenanceActive
                          ? "El modo mantención está actualmente activo. ¿Desea desactivarlo?"
                          : "El modo mantención desactivará el control automático por el tiempo especificado."
                      }}
                    </p>
                  </div>
                </div>
                <div v-if="!isMaintenanceActive" class="mt-5 sm:mt-6">
                  <div class="flex justify-center space-x-6">
                    <div>
                      <label
                        for="hours"
                        class="mb-1 block text-sm font-medium text-gray-700"
                      >
                        Horas
                      </label>
                      <input
                        id="hours"
                        v-model.number="hours"
                        type="number"
                        min="0"
                        max="12"
                        class="block w-24 rounded-md border-gray-300 px-4 py-3 text-center text-2xl shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        @input="validateHours"
                      />
                    </div>
                    <div>
                      <label
                        for="minutes"
                        class="mb-1 block text-sm font-medium text-gray-700"
                      >
                        Minutos
                      </label>
                      <input
                        id="minutes"
                        v-model.number="minutes"
                        type="number"
                        min="0"
                        step="5"
                        :disabled="hours === 12"
                        class="block w-24 rounded-md border-gray-300 px-4 py-3 text-center text-2xl shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        :class="[
                          'block w-24 rounded-md border-gray-300 px-4 py-3 text-center text-2xl shadow-sm focus:border-blue-500 focus:ring-blue-500',
                          { 'bg-gray-100 text-gray-500': hours === 12 },
                        ]"
                        @input="validateMinutes"
                      />
                    </div>
                  </div>
                  <div class="mt-2 text-center">
                    <p class="text-sm text-gray-500">
                      Mínimo 30 minutos, máximo 12 horas.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
              >
                <button
                  v-if="!isMaintenanceActive"
                  type="button"
                  class="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                  :disabled="!isValidDuration"
                  :class="[
                    !isValidDuration
                      ? 'cursor-not-allowed bg-gray-400 opacity-50'
                      : '',
                  ]"
                  @click="activateMaintenanceMode"
                >
                  Activar
                </button>
                <button
                  v-else
                  type="button"
                  class="inline-flex w-full justify-center rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 sm:col-start-2"
                  @click="deactivateMaintenanceMode"
                >
                  Desactivar
                </button>
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  @click="closeModal"
                >
                  Cancelar
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, computed, watch } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    isOpen: Boolean,
    actuatorId: {
      type: String,
      required: true,
    },
    isMaintenanceActive: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close", "activate", "deactivate"],
  setup(props, { emit }) {
    const hours = ref(0);
    const minutes = ref(30);

    const validateMinutes = () => {
      if (minutes.value > 59) {
        minutes.value = 0;
        hours.value = (hours.value + 1) % 13;
      } else if (minutes.value < 0) {
        minutes.value = 59;
        hours.value = hours.value > 0 ? hours.value - 1 : 12;
      }
    };

    const validateHours = () => {
      if (hours.value > 12) {
        hours.value = 0;
      } else if (hours.value < 0) {
        hours.value = 12;
      }
    };

    watch(hours, (newValue) => {
      if (newValue === 12) {
        minutes.value = 0;
      }
    });

    const isValidDuration = computed(() => {
      const totalMinutes = hours.value * 60 + minutes.value;
      return totalMinutes >= 30 && totalMinutes <= 720;
    });

    const closeModal = () => {
      emit("close");
    };

    const activateMaintenanceMode = () => {
      if (isValidDuration.value) {
        const durationInSeconds = (hours.value * 60 + minutes.value) * 60;
        emit("activate", {
          actuatorId: props.actuatorId,
          duration: durationInSeconds,
        });
        closeModal();
      }
    };

    const deactivateMaintenanceMode = () => {
      emit("deactivate", props.actuatorId);
      closeModal();
    };

    return {
      hours,
      minutes,
      validateMinutes,
      validateHours,
      isValidDuration,
      closeModal,
      activateMaintenanceMode,
      deactivateMaintenanceMode,
    };
  },
};
</script>
