<template>
  <section
    id="information-list"
    class="grid w-full"
    :class="sensor ? 'flex' : 'col-span-2'"
  >
    <div class="flex items-center justify-center">
      <div class="mx-auto mb-6 w-full grid-cols-1">
        <section>
          <div :class="sensor ? 'space-y-4' : 'mt-4'">
            <div
              class="flex flex-row justify-center"
              :class="sensor ? 'pt-5' : 'px-1'"
            >
              <div class="w-full">
                <div
                  class="grid h-full gap-4 print:flex-col"
                  :class="
                    sensor ? 'sm:grid-cols-3' : 'grid-rows-1 print:grid-cols-2'
                  "
                >
                  <div v-if="sensor" class="w-full px-2">
                    <div
                      class="relative rounded-lg bg-white shadow-lg print:overflow-hidden print:border-2 print:shadow-none"
                    >
                      <info-tool-tip
                        class="absolute bottom-1 right-1 z-20 text-gray-500 print:hidden"
                        msg="Un alto nivel de CO2 en el aire disminuye la capacidad de toma de decisiones estratégicas y el uso de informacion en empresas o aulas. Esto impacta negativamente en la productividad (Satish et al., 2012). "
                      />

                      <div class="absolute inset-x-0 bottom-0">
                        <canvas
                          id="sensor-productivity-chart"
                          class="rounded-b-lg"
                          height="70"
                        />
                      </div>
                      <div class="relative z-10 px-3 pb-5 pt-4 text-center">
                        <h5
                          class="absolute bottom-0 text-xs text-gray-500 print:hidden"
                        >
                          Últimas 24 horas
                        </h5>
                        <h4
                          class="flex items-center justify-center gap-3 whitespace-nowrap text-sm uppercase leading-tight text-gray-500"
                        >
                          Productividad
                          <font-awesome-icon
                            icon="cogs"
                            class="inline h-5 w-5"
                          />
                        </h4>
                        <h3
                          class="my-3 text-xl font-semibold leading-tight text-gray-700 sm:text-3xl"
                        >
                          {{ sensorProductivity }}%
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="w-full px-2">
                    <div
                      class="relative h-full rounded-lg bg-white shadow-lg print:overflow-hidden print:border-2 print:shadow-none"
                    >
                      <info-tool-tip
                        class="absolute bottom-1 right-1 z-20 text-gray-500 print:hidden"
                        msg="Estudios sugieren que la concentración de CO2 afecta de manera negativa a la asistencia laboral y estudiantil. En una investigación, el aumento de 1000 ppm en los niveles de CO2 provocó un alza en ausentismo de un 10%-20% (Shendell et al., 2004)."
                      />
                      <div class="relative z-10 px-3 pb-5 pt-4 text-center">
                        <h5
                          class="absolute bottom-0 text-xs text-gray-600 print:hidden"
                        >
                          Últimas 24 horas
                        </h5>
                        <h4
                          class="flex items-center justify-center gap-3 whitespace-nowrap text-sm uppercase leading-tight text-gray-600"
                        >
                          Ausentismo
                          <font-awesome-icon
                            icon="user-slash"
                            class="inline h-5 w-5"
                          />
                        </h4>
                        <h3
                          v-if="sensor"
                          class="my-3 font-semibold leading-tight text-gray-700 sm:text-3xl"
                        >
                          {{ sensorAbsenteeismLow }}% -
                          {{ sensorAbsenteeismHigh }}%
                        </h3>
                        <h3
                          v-else
                          class="my-1 whitespace-nowrap text-3xl font-semibold leading-tight text-gray-700"
                        >
                          {{ venueAbsenteeismLow }}% -
                          {{ venueAbsenteeismHigh }}%
                        </h3>
                      </div>
                      <div
                        class="absolute bottom-0"
                        :class="sensor ? 'inset-x-0' : 'h-full w-full'"
                      >
                        <canvas
                          :id="
                            sensor
                              ? 'sensor-absenteeism-chart'
                              : 'venue-absenteeism-chart'
                          "
                          class="rounded-b-lg"
                          height="70"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w-full px-2">
                    <div
                      class="relative h-full rounded-lg bg-white shadow-lg print:overflow-hidden print:border-2 print:shadow-none"
                    >
                      <info-tool-tip
                        class="absolute bottom-1 right-1 z-20 text-gray-500 print:hidden"
                        msg="Según estudios, un alza de 400ppm en los niveles de CO2 provoca una disminución en capacidades cognitivas de 21% (Allen et al., 2016)"
                      />
                      <div class="relative z-10 px-3 pb-5 pt-4 text-center">
                        <h5
                          class="absolute bottom-0 text-xs text-gray-600 print:hidden"
                        >
                          Últimas 24 horas
                        </h5>
                        <h4
                          class="flex items-center justify-center gap-3 whitespace-nowrap text-sm uppercase leading-tight text-gray-600"
                        >
                          Cap. Cognitivas
                          <font-awesome-icon
                            icon="heartbeat"
                            class="inline h-5 w-5"
                          />
                        </h4>
                        <h3
                          v-if="sensor"
                          class="my-3 font-semibold leading-tight text-gray-700 sm:text-3xl"
                        >
                          {{ sensorCognition }}%
                        </h3>
                        <h3
                          v-else
                          class="my-1 text-3xl font-semibold leading-tight text-gray-700"
                        >
                          {{ venueCognition }}%
                        </h3>
                      </div>
                      <div
                        class="absolute bottom-0"
                        :class="
                          sensor ? 'inset-x-0 print:overflow-hidden' : 'w-full'
                        "
                      >
                        <canvas
                          :id="
                            sensor
                              ? 'sensor-cognition-chart'
                              : 'venue-cognition-chart'
                          "
                          class="rounded-b-lg"
                          height="70"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import {
  CategoryScale,
  Chart,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from "chart.js";
import { buttonMixin, listenerMixin, showMixin } from "../../mixins";
import InfoToolTip from "./InfoToolTip.vue";
import pattern from "patternomaly";
Chart.register(
  CategoryScale,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title
);

export default {
  name: "InformationList",
  components: {
    InfoToolTip,
  },
  mixins: [buttonMixin, listenerMixin, showMixin],
  props: {
    sensor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartOptions: {
        maintainAspectRatio: false,
        elements: {
          line: {
            fill: true,
            borderWidth: 2,
          },
          point: {
            radius: 0,
            hitRadius: 0,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            suggestedMin: 0,
            suggestedMax: 101,
          },
        },
      },
      errorMsg: "",
    };
  },
  computed: {
    venueAbsenteeismData() {
      return this.venueInfos.map((e) => e.absenteeism);
    },
    venueAbsenteeismLow() {
      const v = this.venueInfo?.absenteeismLow;
      return !v || isNaN(v) ? "" : Math.trunc(v);
    },
    venueAbsenteeismHigh() {
      const v = this.venueInfo?.absenteeismHigh;
      return !v || isNaN(v) ? "" : Math.trunc(v);
    },
    venueCognition() {
      const v = this.venueInfo?.cognition;
      return !v || isNaN(v) ? "-" : Math.trunc(v);
    },
    venueCognitionData() {
      return this.venueInfos.map((e) => e.cognition);
    },
    currentVenue() {
      return this.$store.state.currentVenue;
    },
    venueInfo() {
      return this.$store.state.venueInfo;
    },
    venueInfos() {
      return this.$store.state.venueInfos;
    },
    sensorAbsenteeismData() {
      return this.sensorInfos.map((e) => Math.max(0, e.absenteeism));
    },
    sensorAbsenteeismLow() {
      const v = this.sensorInfo?.absenteeismLow;
      return !v || isNaN(v) ? "" : Math.trunc(v);
    },
    sensorAbsenteeismHigh() {
      const v = this.sensorInfo?.absenteeismHigh;
      return !v || isNaN(v) ? "" : Math.trunc(v);
    },
    sensorCognition() {
      const v = this.sensorInfo?.cognition;
      return !v || isNaN(v) ? "-" : Math.trunc(v);
    },
    sensorCognitionData() {
      return this.sensorInfos.map((e) => e.cognition);
    },
    sensorProductivity() {
      const v = this.sensorInfo?.productivity;
      return !v || isNaN(v) ? "-" : Math.trunc(v);
    },
    sensorProductivityData() {
      return this.sensorInfos.map((e) => e.productivity);
    },
    sensorInfo() {
      return this.$store.state.sensorInfo;
    },
    sensorInfos() {
      return this.$store.state.sensorInfos;
    },
  },
  watch: {
    venueInfo: {
      handler: "plotVenueInfo",
    },
    sensorInfo: {
      inmediate: true,
      handler: "plotSensorInfo",
    },
  },
  unmounted() {
    window.removeEventListener(
      "resize",
      this.plotVenueInfo,
      this.plotSensorInfo
    );
  },
  mounted() {
    if (this.sensor) {
      this.plotSensorInfo();
    } else {
      this.plotVenueInfo();
    }
    window.addEventListener("resize", this.plotVenueInfo, this.plotSensorInfo);
  },
  methods: {
    plotVenueInfo() {
      this.plotChart(
        "venue-absenteeism-chart",
        this.venueAbsenteeismData,
        this.colors.absenteeism,
        true
      );
      this.plotChart(
        "venue-cognition-chart",
        this.venueCognitionData,
        this.colors.cognition
      );
    },
    plotSensorInfo() {
      if (this.sensor) {
        this.plotChart(
          "sensor-productivity-chart",
          this.sensorProductivityData,
          this.colors.productivity
        );
        this.plotChart(
          "sensor-absenteeism-chart",
          this.sensorAbsenteeismData,
          this.colors.absenteeism,
          true
        );
        this.plotChart(
          "sensor-cognition-chart",
          this.sensorCognitionData,
          this.colors.cognition
        );
      }
    },
    plotChart(chartId, data, color, absenteeism = false) {
      let opt = { ...this.chartOptions };
      if (
        chartId == "sensor-absenteeism-chart" ||
        chartId == "venue-absenteeism-chart"
      ) {
        opt["scales"]["y"]["suggestedMax"] = 20;
      }
      Chart.getChart(chartId)?.destroy();
      let ctx = document.getElementById(chartId).getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: {
          labels: data,
          datasets: absenteeism
            ? [
                {
                  backgroundColor: this.setOpacity(color, 1),
                  borderColor: this.setOpacity(color, 0.3),
                  data: data.map((v) => v),
                },
                {
                  backgroundColor: pattern.draw(
                    "diagonal-right-left",
                    this.setOpacity(color, 0.4)
                  ),
                  borderColor: this.setOpacity(color, 0.8),
                  data: data.map((v) => v + 10),
                },
              ]
            : [
                {
                  backgroundColor: this.setOpacity(color, 0.1),
                  borderColor: this.setOpacity(color, 0.8),
                  data: data.map((v) => v),
                },
              ],
        },
        options: opt,
      });
    },
  },
};
</script>
