<template>
  <transition>
    <div
      class="modal-overlay fixed bottom-0 left-0 right-0 top-0 z-50 flex justify-center overflow-y-scroll bg-black bg-opacity-30"
      tabindex="0"
      @click="$emit('close-modal')"
    >
      <div
        :class="modalClass"
        class="mx-2 sm:mx-4"
        @click.stop=""
        @mousedown="$emit('in-modal')"
      >
        <div class="flex w-full flex-row-reverse justify-between p-2">
          <div
            class="close-modal mr-1 mt-1 flex cursor-pointer"
            @click="$emit('close-modal')"
          >
            <font-awesome-icon
              icon="times"
              class="inline h-8 w-8 cursor-pointer text-gray-700"
            />
          </div>
          <div v-if="isConfigurable" class="dimension-toggle ml-1 mt-1 flex">
            <font-awesome-icon
              icon="cog"
              class="inline h-8 w-8 cursor-pointer"
              :class="showConfig ? 'text-gray-700' : 'text-flairblue'"
              @click="$emit('toggle-config')"
            />
          </div>
        </div>

        <transition name="slide-fade">
          <div class="px-2 sm:px-6">
            <slot name="body"> This is the default Body! </slot>
          </div>
        </transition>
        <div>
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
  props: {
    isConfigurable: {
      type: Boolean,
      default: false,
    },
    modalClass: {
      type: String,
      default: "modal",
    },
  },
  emits: ["close-modal", "in-modal", "toggle-config"],
  data() {
    return {
      showConfig: true,
    };
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: 0.8s;
}
.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from {
  transform: translateX(60px);
  opacity: 0.2;
}
.modal {
  text-align: center;
  background-color: white;
  height: fit-content;
  width: 800px;
  position: relative;
  margin-top: 2%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  font-size: 16px;
  margin: 20px 0;
}
</style>
