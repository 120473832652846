<template>
  <modal
    modal-class="pass-modal self-center text-center bg-white h-fit w-96 rounded-xl m-0"
    @close-modal="$emit('close-modal')"
  >
    <template #header>
      <div class="pb-5 pt-1 text-3xl font-medium">Cambiar contraseña</div>
    </template>
    <template #body>
      <form class="w-full justify-items-center rounded" @submit.prevent>
        <text-field
          id="current-pass"
          v-model="oldPassword"
          label="Contraseña actual"
          placeholder="********"
          type="password"
        />
        <text-field
          id="new-pass"
          v-model="newPassword"
          label="Contraseña nueva"
          placeholder="********"
          type="password"
        />
        <div
          v-if="Boolean(errorMsg)"
          class="flex justify-center font-bold text-red-500"
        >
          {{ errorMsg }}
        </div>
        <tri-state-button
          :funct="changePassword"
          label="Cambiar contraseña"
          :disabled="invalidPasswords"
          :state="buttonState"
        />
      </form>
    </template>
    <template #footer />
  </modal>
</template>
<script>
import Modal from "../cards/Modal.vue";
import TextField from "../TextField.vue";
import TriStateButton from "../common/TriStateButton.vue";
import { buttonMixin } from "../../mixins";

export default {
  name: "ChangePassword",
  components: {
    TextField,
    TriStateButton,
    Modal,
  },
  mixins: [buttonMixin],
  emits: ["close-modal"],
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      buttonState: "default",
      errorMsg: "",
    };
  },
  computed: {
    invalidPasswords() {
      return this.oldPassword.length < 6 || this.newPassword.length < 6;
    },
    passwordParam() {
      const { oldPassword, newPassword } = this;
      return { oldPassword, newPassword };
    },
  },
  watch: {
    passwordParam: {
      deep: true,
      handler: "resetButton",
    },
  },

  methods: {
    async changePassword() {
      const { oldPassword, newPassword, invalidPasswords } = this;
      if (invalidPasswords) {
        this.errorMsg = "La contraseña debe tener un largo mínimo de 6 dígitos";
      } else {
        this.errorMsg = "";
        this.$store
          .dispatch("changePassword", { oldPassword, newPassword })
          .then(() => {
            this.buttonState = "success";
          })
          .catch(({ response: { status } }) => {
            switch (status) {
              case 401:
                this.errorMsg = "Contraseña errónea";
                break;
              default:
                this.errorMsg = "Error de conexión";
                break;
            }
            this.buttonState = "error";
          });
      }
    },
  },
};
</script>
