<template>
  <div ref="alerts" class="bg-white" :style="{ height: combinedHeight + 'px' }">
    <div class="sticky top-0 z-10 bg-white">
      <div
        class="flex items-center justify-between border-b border-gray-300 p-5"
      >
        <h3 class="text-lg font-semibold text-gray-900">Alertas</h3>
      </div>

      <div class="border-b border-gray-300">
        <multiple-select
          :options="allActuators"
          @update:selection="updateSelection"
        />
      </div>

      <div class="flex border-b border-gray-300">
        <button
          :class="{ 'border-b-2 border-blue-400': currentTab === 'new' }"
          class="flex-1 p-4 text-center text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
          @click="changeTab('new')"
        >
          Principal
        </button>
        <button
          :class="{ 'border-b-2 border-blue-400': currentTab === 'resolved' }"
          class="flex-1 p-4 text-center text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
          @click="changeTab('resolved')"
        >
          Resueltas
        </button>
        <button
          :class="{ 'border-b-2 border-blue-400': currentTab === 'discarded' }"
          class="flex-1 p-4 text-center text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
          @click="changeTab('discarded')"
        >
          Descartadas
        </button>
      </div>
    </div>

    <div class="h-[calc(100%-10rem)] overflow-y-auto">
      <div v-if="isLoading">
        <div class="flex items-center justify-center p-10">
          <font-awesome-icon
            icon="spinner"
            class="h-6 w-6 animate-spin text-gray-400"
          />
        </div>
      </div>

      <div v-else-if="alerts.length === 0">
        <div class="flex items-center justify-center p-10">
          <span class="text-sm text-gray-500"
            >No hay alertas en la última semana</span
          >
        </div>
      </div>

      <ul v-else class="divide-y divide-gray-200">
        <li
          v-for="(event, eventIdx) in filteredAlerts"
          :key="eventIdx"
          class="relative flex flex-col justify-between px-4 py-4"
        >
          <div class="mb-2 flex items-start space-x-3">
            <span
              v-if="event.correct === undefined || event.correct === null"
              class="mt-1 h-2 w-2 flex-shrink-0 rounded-full bg-blue-500"
            ></span>
            <span
              :class="[
                event.iconBackground,
                'flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full',
              ]"
            >
              <font-awesome-icon
                :icon="event.icon"
                class="h-5 w-5 text-white"
              />
            </span>
            <div class="min-w-0 flex-1">
              <p class="text-sm text-gray-500">{{ event.text }}</p>
            </div>
          </div>

          <div class="mt-2 flex items-center justify-between">
            <div class="flex space-x-4 text-xs text-gray-500">
              <span>Desde: {{ event.date }} {{ event.time }}</span>
              <span>Duración: {{ event.duration }}</span>
            </div>
            <div class="relative flex items-center">
              <template v-if="currentTab === 'new'">
                <button
                  type="button"
                  title="Confirmar que alerta es real"
                  class="flex items-center justify-center rounded-full border-2 border-green-500 bg-white p-1 text-green-500 shadow-sm hover:bg-green-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  style="width: 1.5rem; height: 1.5rem"
                  @click.stop="
                    updateAlert(
                      event.actuator_id,
                      event.alerts[0].timestamp,
                      true
                    )
                  "
                >
                  <font-awesome-icon
                    icon="check"
                    style="width: 0.75rem; height: 0.75rem"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  title="Alerta fue un error o ya fue resuelta"
                  class="ml-2 flex items-center justify-center rounded-full border-2 border-red-500 bg-white p-1 text-red-500 shadow-sm hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  style="width: 1.5rem; height: 1.5rem"
                  @click.stop="
                    updateAlert(
                      event.actuator_id,
                      event.alerts[0].timestamp,
                      false
                    )
                  "
                >
                  <font-awesome-icon
                    icon="times"
                    style="width: 0.75rem; height: 0.75rem"
                    aria-hidden="true"
                  />
                </button>
              </template>

              <div
                v-if="currentTab === 'resolved' || currentTab === 'discarded'"
                class="relative"
              >
                <button
                  v-if="currentTab === 'resolved' || currentTab === 'discarded'"
                  type="button"
                  class="ml-2 p-2"
                  @click.stop="toggleMenu(eventIdx)"
                >
                  <font-awesome-icon icon="ellipsis-v" />
                </button>

                <div
                  v-if="event.showMenu"
                  class="absolute right-0 z-20 mt-2 w-48 rounded-md bg-white shadow-lg"
                  style="top: 100%; right: 0"
                  @click.stop
                >
                  <div class="py-1">
                    <button
                      class="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                      @click="deleteAlert(event.actuator_id, event.alerts)"
                    >
                      Eliminar
                    </button>
                    <button
                      class="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                      @click="
                        markAsUnresolved(
                          event.actuator_id,
                          event.alerts[0].timestamp
                        )
                      "
                    >
                      Marcar como no resuelta
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <div class="border-b border-gray-300"></div>
      </ul>
    </div>
  </div>
</template>

<script>
import MultipleSelect from "../common/Multiselect.vue";
export default {
  name: "ActuatorsAlerts",
  components: {
    MultipleSelect,
  },
  data() {
    return {
      alerts: [],
      pollInterval: null,
      currentTab: "new",
      selectedActuatorsIds: [],
      isLoading: false,
    };
  },
  computed: {
    venueName() {
      return this.$store.state.currentVenue.name;
    },
    allActuators() {
      return this.$store.state.venueActuators;
    },
    filteredAlerts() {
      return this.alerts
        .filter(
          (alert) =>
            this.selectedActuatorsIds.length === 0 ||
            this.selectedActuatorsIds.includes(alert.actuator_id)
        )
        .filter((alert) => {
          if (this.currentTab === "new")
            return alert.correct === undefined || alert.correct === null;
          if (this.currentTab === "resolved") return alert.correct === true;
          if (this.currentTab === "discarded") return alert.correct === false;
          return true;
        });
    },
    combinedHeight() {
      const gridHeight = this.$store.state.actuatorsGridHeight;
      const statsInlineHeight = this.$store.state.statsInlineHeight;
      const buffer = 33;
      return gridHeight + statsInlineHeight + buffer;
    },
  },
  watch: {
    "$store.state.currentVenue": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.alerts = [];
        this.loadAlerts();
      }
    },
    currentVenue: {
      handler() {
        this.loadAlerts();
      },
      deep: true,
    },
    currentTab() {
      this.closeAllMenus();
    },
  },
  created() {
    this.loadAlerts();
    this.startPolling();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    this.stopPolling();
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    async loadAlerts() {
      this.isLoading = true;
      await this.$store.dispatch("loadVenueActuatorsAlerts");
      this.alerts = this.translateAlertsToTimeline(
        this.$store.state.venueActuatorsAlerts
      );
      this.isLoading = false;
    },
    translateAlertsToTimeline(alerts) {
      return alerts.map((alert) => ({
        text: this.getTextFromAlert(alert),
        date: new Date(alert.timestamp).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        }),
        time: new Date(alert.timestamp).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        icon: this.getIconFromAlertType(alert.alertType),
        iconBackground: this.getIconBackgroundFromAlertType(alert.alertType),
        timestamp: alert.timestamp,
        duration: this.formatDuration(alert.duration),
        correct: alert?.correct,
        actuator_id: alert.uuid,
        showMenu: alert.showMenu || false,
        alerts: alert.alerts,
      }));
    },
    formatDuration(duration) {
      if (duration > 60) {
        const hours = Math.floor(duration / 60);
        const minutes = Math.floor(duration % 60);
        if (minutes === 0) {
          return `${hours} hora${hours !== 1 ? "s" : ""}`;
        }
        return `${hours} hora${hours !== 1 ? "s" : ""} ${minutes} minuto${
          minutes !== 1 ? "s" : ""
        }`;
      } else {
        return `${duration.toFixed(0)} minuto${duration !== 1 ? "s" : ""}`;
      }
    },
    getTextFromAlert(alert) {
      let alertMessage = `Temperatura: ${alert.value}.`;
      switch (alert.alertType) {
        case "ambient_high_temp":
          alertMessage = `${alert.name}: Dificultad en el enfriamiento del ambiente.`;
          break;
        case "ambient_low_temp":
          alertMessage = `${alert.name}: Dificultad en el calentamiento del ambiente.`;
          break;
        case "difusor_high_temp":
          alertMessage = `${alert.name}: Temperatura del difusor es muy alta con valor ${alert.value} °C.`;
          break;
        case "difusor_low_temp":
          alertMessage = `${alert.name}: Temperatura del difusor es muy baja con valor ${alert.value} °C.`;
          break;
        default:
          alertMessage = `${alert.name}: Tipo de alerta desconocida: ${alert.alertType}`;
          break;
      }
      return alertMessage;
    },
    getIconFromAlertType() {
      return "circle-exclamation";
    },
    getIconBackgroundFromAlertType(alertType) {
      switch (alertType) {
        case "ambient_high_temp":
        case "ambient_low_temp":
          return "bg-yellow-400";
        case "difusor_high_temp":
        case "difusor_low_temp":
          return "bg-red-400";
        default:
          return "bg-gray-400";
      }
    },
    startPolling() {
      this.pollInterval = setInterval(this.loadAlerts, 121000);
    },
    stopPolling() {
      clearInterval(this.pollInterval);
    },
    async updateAlert(id, timestamp, confirmed) {
      const data = { timestamp: timestamp, correct: confirmed };
      await this.$store.dispatch("updateActuatorAlert", { id: id, data: data });
      this.loadAlerts();
    },
    highlightActuator(actuatorId) {
      this.$store.commit("setHighlightedActuatorId", actuatorId);
      setTimeout(() => {
        this.$store.commit("setHighlightedActuatorId", null);
      }, 5000);
    },
    updateSelection(selection) {
      this.selectedActuatorsIds = selection.map((actuator) => actuator.id);
    },
    toggleMenu(eventIdx) {
      this.filteredAlerts.forEach((alert, idx) => {
        if (idx !== eventIdx) {
          alert.showMenu = false;
        }
      });
      this.filteredAlerts[eventIdx].showMenu =
        !this.filteredAlerts[eventIdx].showMenu;
    },
    async deleteAlert(id, alerts) {
      await this.$store.dispatch("deleteActuatorAlert", { id, alerts });
      this.loadAlerts();
    },
    async markAsUnresolved(id, timestamp) {
      const data = { timestamp, correct: null };
      await this.$store.dispatch("updateActuatorAlert", { id, data });
      this.loadAlerts();
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeAllMenus();
      }
    },
    closeAllMenus() {
      this.alerts.forEach((alert) => {
        alert.showMenu = false;
      });
    },
    changeTab(tab) {
      this.currentTab = tab;
      this.closeAllMenus();
    },
  },
};
</script>
