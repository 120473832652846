<template>
  <hoverable>
    <template #default="{ hover }">
      <a :href="href">
        <div
          class="w-auto rounded-lg px-2 py-2 text-sm font-medium md:px-6"
          :class="{
            'bg-flairblue text-white': hover || active,
            'cursor-default': active,
          }"
        >
          <div class="flex w-full flex-row justify-center gap-2">
            <font-awesome-icon :icon="icon" class="my-auto h-4 w-4" />
            <p class="sm:hidden md:hidden lg:inline">
              {{ text }}
            </p>
          </div>
        </div>
      </a>
    </template>
  </hoverable>
</template>

<script>
import Hoverable from "../common/Hoverable.vue";

export default {
  name: "NavbarLink",

  components: {
    Hoverable,
  },

  props: {
    pageId: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "#",
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    currentPage() {
      return this.$store.state.page;
    },
    active() {
      return this.pageId === this.currentPage;
    },
  },
};
</script>
