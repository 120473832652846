<template>
  <div class="flex justify-center gap-2 sm:flex">
    <button class="mb-12 mt-8 hover:bg-gray-100" @click="leftChart">
      <font-awesome-icon
        icon="angle-left"
        class="h-4 w-4 text-gray-700 sm:m-2 sm:h-8 sm:w-8"
      />
    </button>
    <variable-chart class="w-full" v-bind="variables[selectedChart]" />
    <button class="mb-12 mt-8 hover:bg-gray-100" @click="rightChart">
      <font-awesome-icon
        icon="angle-right"
        class="m-2 h-4 w-4 text-gray-700 sm:h-8 sm:w-8"
      />
    </button>
  </div>
</template>
<script>
import VariableChart from "../cards/VariableChart.vue";

export default {
  name: "MeasurementsChart",
  components: {
    VariableChart,
  },
  data() {
    return {
      variables: [
        { variable: "co2", humanized: "CO2", unit: "ppm" },
        { variable: "temperature", humanized: "Temperatura", unit: "C°" },
        { variable: "humidity", humanized: "Humedad", unit: "%" },
      ],
      selectedChart: 0,
    };
  },
  methods: {
    leftChart() {
      if (this.selectedChart) {
        this.selectedChart--;
      } else {
        this.selectedChart = 2;
      }
    },
    rightChart() {
      this.selectedChart = (this.selectedChart + 1) % 3;
    },
  },
};
</script>
