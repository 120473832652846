<template>
  <div>
    <h3 class="text-base font-semibold leading-6 text-gray-900">Este mes</h3>
    <dl
      v-if="!isLoading"
      class="mt-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0"
    >
      <div class="px-4 py-5 sm:p-6">
        <dt
          class="flex items-baseline justify-between text-base font-normal text-gray-900"
        >
          Acumulado
          <div class="space-x-2 text-xs">
            <button
              :class="[
                'font-medium',
                isShowingDaily ? 'text-blue-600' : 'text-gray-500',
              ]"
              @click="isShowingDaily = true"
            >
              Diario
            </button>
            <button
              :class="[
                'font-medium',
                !isShowingDaily ? 'text-blue-600' : 'text-gray-500',
              ]"
              @click="isShowingDaily = false"
            >
              Mensual
            </button>
          </div>
        </dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold text-blue-600">
            {{
              isShowingDaily
                ? dailyAccumulatedKwh?.dailyAccumulatedKwh?.toFixed(1) || "-"
                : monthlyAccumulatedKwh?.monthlyAccumulatedKwh?.toFixed(1) ||
                  "-"
            }}
            <span class="ml-2 text-sm font-medium text-gray-500"> kWh </span>
          </div>
          <div
            v-if="
              selectedAccumulatedKwh?.percentChange !== 0 &&
              !isNaN(selectedAccumulatedKwh?.percentChange)
            "
            :class="[
              isSaving
                ? 'bg-red-100 text-red-800'
                : 'bg-green-100 text-green-800',
              'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
            ]"
          >
            <font-awesome-icon
              v-if="isSaving"
              icon="angle-up"
              class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
              aria-hidden="true"
            />
            <font-awesome-icon
              v-else
              icon="angle-down"
              class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
              aria-hidden="true"
            />
            <span class="sr-only">
              {{ isSaving ? "Aumento" : "Disminuyo" }}
              en
            </span>
            {{ selectedAccumulatedKwh?.percentChange?.toFixed(1) }}%
          </div>
        </dd>
      </div>
      <div
        v-if="consumptionGoal.current !== '-' && consumptionGoal.total !== '-'"
        class="px-4 py-5 sm:p-6"
      >
        <dt class="text-base font-normal text-gray-900">Meta de consumo</dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold text-blue-600">
            {{ consumptionGoal.current }}
            <span class="ml-1 text-sm font-medium text-gray-500"
              >/{{ consumptionGoal.total }}</span
            >
            <span class="ml-2 text-sm font-medium text-gray-500">kWh </span>
          </div>
          <div
            class="inline-flex items-baseline rounded-full bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800 md:mt-2 lg:mt-0"
          >
            {{
              ((consumptionGoal.current / consumptionGoal.total) * 100).toFixed(
                1
              ) || 0
            }}%
          </div>
        </dd>
      </div>
      <div v-else class="px-4 py-5 sm:p-6">
        <dt class="text-base font-normal text-gray-900">Meta de consumo</dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold text-blue-600">
            {{ consumptionGoal.current }}
            <span class="ml-2 text-sm font-medium text-gray-500">kWh </span>
          </div>
        </dd>
      </div>
      <div v-for="item in otherStats" :key="item.name" class="px-4 py-5 sm:p-6">
        <dt class="text-base font-normal text-gray-900">{{ item.name }}</dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold text-blue-600">
            {{ item.value }}
            <span class="ml-2 text-sm font-medium text-gray-500">
              {{ item.unit }}</span
            >
          </div>
        </dd>
      </div>
    </dl>
    <div v-else class="flex h-24 items-center justify-center">
      <font-awesome-icon
        icon="spinner"
        class="h-12 w-12 animate-spin text-gray-600"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsInline",
  data() {
    return {
      isShowingDaily: true,
    };
  },
  computed: {
    otherStats() {
      return [
        {
          name: "Promedio diario",
          value: this.dailyAverageKwh,
          unit: "kWh",
        },
      ];
    },
    dailyAccumulatedKwh() {
      return this.$store.state.dailyAccumulatedKwh;
    },
    monthlyAccumulatedKwh() {
      return this.$store.state.monthlyAccumulatedKwh;
    },
    selectedAccumulatedKwh() {
      return this.isShowingDaily
        ? this.dailyAccumulatedKwh
        : this.monthlyAccumulatedKwh;
    },
    dailyAverageKwh() {
      return this.$store.state.dailyAverageKwh?.dailyAverage?.toFixed(1) || "-";
    },
    consumptionGoal() {
      let totalMonthlyKwh =
        this.monthlyAccumulatedKwh?.previousMonthAccumulatedKwh;
      if (totalMonthlyKwh < 9000) {
        totalMonthlyKwh = 9000;
      }
      const current =
        this.monthlyAccumulatedKwh?.monthlyAccumulatedKwh?.toFixed(0);
      const total = totalMonthlyKwh?.toFixed(0);
      return {
        current: current ? current : "-",
        total: total ? total : "-",
      };
    },
    isSaving() {
      if (this.isShowingDaily) {
        return (
          this.dailyAccumulatedKwh?.dailyAccumulatedKwh >
          this.dailyAccumulatedKwh?.previousDayAccumulatedKwh
        );
      } else {
        return (
          this.monthlyAccumulatedKwh?.monthlyAccumulatedKwh >
          this.monthlyAccumulatedKwh?.previousMonthAccumulatedKwh
        );
      }
    },
    isLoading() {
      return this.$store.state.loadingEnergyMeters;
    },
  },
  watch: {
    isShowingDaily() {
      this.emitHeight();
    },
  },
  mounted() {
    this.emitHeight();
  },
  methods: {
    emitHeight() {
      this.$nextTick(() => {
        const height = this.$el.clientHeight;
        this.$store.dispatch("updateStatsInLineHeight", height);
      });
    },
  },
};
</script>
