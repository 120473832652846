<template>
  <form class="space-y-4" @submit.prevent="onSave">
    <div class="grid grid-cols-3 items-baseline gap-4">
      <label
        for="scheduleType"
        class="col-span-1 text-right text-sm font-medium text-gray-700"
      >
        Tipo de Calendarización
      </label>
      <div class="col-span-2 flex flex-col">
        <select
          id="scheduleType"
          v-model="selectedType"
          :disabled="isTypeReadOnly"
          :class="[
            'mt-1 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm',
            isTypeReadOnly ? 'cursor-not-allowed bg-gray-100' : '',
          ]"
        >
          <option value="setpoint">Setpoint</option>
          <option value="onoff">Apagado</option>
          <option value="controlcycle">Control Cycle</option>
          <option value="unitmode">Modo de funcionamiento</option>
          <option value="ecomode">Modo Económico</option>
          <option value="ecomode2">Modo Económico 2</option>
          <option value="customcontrolcycle">
            Ciclo de Control Personalizado
          </option>
        </select>
      </div>
    </div>

    <div class="grid grid-cols-3 items-baseline gap-4">
      <label
        for="name"
        class="col-span-1 text-right text-sm font-medium text-gray-700"
      >
        Nombre
      </label>
      <div class="col-span-2 flex flex-col">
        <input
          id="name"
          v-model="form.name"
          type="text"
          maxlength="20"
          placeholder="Nombre"
          :class="[
            'mt-1 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm',
            validation.name
              ? 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600'
              : 'ring-2 ring-red-500 focus:ring-2 focus:ring-red-600',
          ]"
        />
        <span
          :class="[
            'mt-1 text-xs text-red-500',
            validation.name ? 'invisible' : 'visible',
          ]"
        >
          Este campo es requerido
        </span>
      </div>
    </div>
    <div class="grid grid-cols-3 items-baseline gap-4">
      <label
        for="startTime"
        class="col-span-1 text-right text-sm font-medium text-gray-700"
      >
        Hora Inicio
      </label>
      <div class="col-span-2 flex flex-col">
        <input
          id="startTime"
          v-model="form.start_time"
          type="time"
          :class="[
            'mt-1 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm',
            validation.startTime
              ? 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600'
              : 'ring-2 ring-red-500 focus:ring-2 focus:ring-red-600',
          ]"
        />
        <span
          :class="[
            'mt-1 text-xs text-red-500',
            validation.startTime ? 'invisible' : 'visible',
          ]"
        >
          Este campo es requerido
        </span>
      </div>
    </div>

    <div class="grid grid-cols-3 items-baseline gap-4">
      <label
        for="endTime"
        class="col-span-1 text-right text-sm font-medium text-gray-700"
      >
        Hora Término
      </label>
      <div class="col-span-2 flex flex-col">
        <input
          id="endTime"
          v-model="form.end_time"
          type="time"
          :class="[
            'mt-1 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm',
            validation.endTime
              ? 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600'
              : 'ring-2 ring-red-500 focus:ring-2 focus:ring-red-600',
          ]"
        />
        <span
          :class="[
            'mt-1 text-xs text-red-500',
            validation.endTime ? 'invisible' : 'visible',
          ]"
        >
          Este campo es requerido
        </span>
      </div>
    </div>

    <div class="grid grid-cols-3 items-center gap-4">
      <label
        for="active-switch"
        class="col-span-1 text-right text-sm font-medium text-gray-700"
      >
        Habilitar
      </label>
      <div class="col-span-2 mt-1">
        <Switch
          id="active-switch"
          v-model="form.active"
          :class="[
            form.active ? 'bg-blue-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          ]"
          @click="userUpdatePending = true"
        >
          <span
            aria-hidden="true"
            :class="[
              form.active ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            ]"
          />
        </Switch>
      </div>
    </div>
    <div
      v-if="selectedType === 'setpoint'"
      class="grid grid-cols-3 items-baseline gap-4"
    >
      <label
        for="setpointPresetId"
        class="col-span-1 text-right text-sm font-medium text-gray-700"
      >
        Setpoints
      </label>
      <div class="col-span-2 flex flex-col">
        <SetpointPresetDropdown
          :actuator="actuator"
          :schedule-preset-id="form.setpoint_preset_id"
          class="col-span-2"
          :validation="validation.setpointPresetId"
          @update:selectedPresetId="handlePresetSelection"
        />
        <span
          :class="[
            'mt-1 text-xs text-red-500',
            validation.setpointPresetId ? 'invisible' : 'visible',
          ]"
        >
          Este campo es requerido
        </span>
      </div>
    </div>

    <div v-if="selectedType === 'onoff'">
      <input type="hidden" :value="form.on_off" />
    </div>

    <!-- <div v-if="selectedType === 'ecomode'">
      <input type="hidden" :value="form.on_off" />
    </div> -->

    <div
      v-if="selectedType === 'unitmode'"
      class="grid grid-cols-3 items-baseline gap-4"
    >
      <label
        for="unitMode"
        class="col-span-1 text-right text-sm font-medium text-gray-700"
      >
        Modo de funcionamiento
      </label>
      <div class="col-span-2 flex flex-col">
        <select
          id="unitMode"
          v-model="form.unit_mode"
          :class="[
            'mt-1 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm',
            validation.unitMode
              ? 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600'
              : 'ring-2 ring-red-500 focus:ring-2 focus:ring-red-600',
          ]"
        >
          <option value="heat">Modo Calor</option>
          <option value="cool">Modo Frio</option>
          <option value="fan">Ventilar</option>
        </select>
        <span
          :class="[
            'mt-1 text-xs text-red-500',
            validation.unitMode ? 'invisible' : 'visible',
          ]"
        >
          Este campo es requerido
        </span>
      </div>
    </div>

    <div
      v-if="selectedType === 'customcontrolcycle'"
      class="grid grid-cols-3 items-baseline gap-4"
    >
      <label
        for="customControlCycle"
        class="col-span-1 text-right text-sm font-medium text-gray-700"
      >
        Ciclo de Control Personalizado
      </label>
      <div class="col-span-2 flex flex-col">
        <select
          id="customControlCycle"
          v-model="form.custom_control_cycle_id"
          :class="[
            'mt-1 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 sm:text-sm',
            validation.customControlCycle
              ? 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600'
              : 'ring-2 ring-red-500 focus:ring-2 focus:ring-red-600',
          ]"
        >
          <option value="">Seleccionar un Ciclo de Control</option>
          <option
            v-for="cycle in customControlCycles"
            :key="cycle.id"
            :value="cycle.id"
          >
            {{ cycle.name }}
          </option>
        </select>
        <p
          v-if="customControlCycles.length === 0"
          class="mt-1 text-xs text-red-500"
        >
          No hay ciclos de control disponibles
        </p>
      </div>
    </div>

    <div class="flex justify-end space-x-2">
      <button
        v-if="form.id"
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        @click="onDelete"
      >
        Eliminar
      </button>
      <button
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        @click="cancel"
      >
        Cancelar
      </button>
      <button
        type="submit"
        :class="[
          'inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
          isDefaultState
            ? 'cursor-not-allowed bg-gray-400 text-gray-700'
            : 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500',
        ]"
        :disabled="isDefaultState"
      >
        Guardar
      </button>
    </div>
  </form>
</template>

<script>
import SetpointPresetDropdown from "./SetpointPresetDropdown.vue";
import { Switch } from "@headlessui/vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    SetpointPresetDropdown,
    Switch,
  },
  props: {
    schedule: {
      type: Object,
      default: () => ({}),
    },
    isRecurring: {
      type: Boolean,
      default: false,
    },
    selectedDates: {
      type: Array,
      default: () => [],
    },
    actuator: {
      type: Object,
      default: () => ({
        id: "",
        type: "",
      }),
    },
    recurrentScheduleSettings: {
      type: Object,
      default: () => ({
        selectedDays: [],
        frequency: 1,
        intervalUnit: "weeks",
        endDate: new Date().toISOString().substring(0, 10),
      }),
    },
    scheduleType: {
      type: String,
      default: "setpoint",
    },
    isTypeReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["create", "update", "cancel", "delete"],
  data() {
    return {
      selectedType: this.schedule?.type || this.scheduleType,
      form: this.initializeForm(),
      validation: {
        name: true,
        startTime: true,
        endTime: true,
        setpointPresetId: true,
        unitMode: true,
        customControlCycle: true,
        onOff: true,
        ecoMode: true,
        eco2Mode: true,
        controlCycle: true,
      },
      placeholders: {
        name: "Schedule Name",
        start_time: "Start Time",
        end_time: "End Time",
        start_date: "Start Date",
        end_date: "End Date",
        active: "Active",
        is_recurring: "Is Recurring",
      },
    };
  },
  computed: {
    ...mapState(["customControlCycles"]),
    ...mapGetters(["getCustomControlCycles"]),
    isDefaultState() {
      if (this.isRecurring) {
        return this.recurrentScheduleSettings.selectedDays.length === 0;
      } else {
        return this.selectedDates.length === 0;
      }
    },
  },
  watch: {
    recurrentScheduleSettings: {
      handler(newVal) {
        this.form.is_recurring = newVal.selectedDays.length > 0;
        this.form.days_of_week = this.mapDaysOfWeek(newVal.selectedDays);
        this.form.end_date = newVal.endDate;
        this.form.frequency = newVal.frequency;
        this.form.interval_unit = newVal.intervalUnit;
      },
      deep: true,
    },
    selectedDates(newVal) {
      this.form.dates = newVal;
    },
    scheduleType(newType) {
      this.selectedType = newType;
      this.form = this.initializeForm();
    },
    schedule: {
      handler() {
        this.form = this.initializeForm();
      },
      deep: true,
    },
    selectedType: {
      handler() {
        Object.keys(this.validation).forEach((key) => {
          this.validation[key] = true;
        });
        this.form = this.initializeForm();
      },
      immediate: true,
    },
    "actuator.id": {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.loadCustomControlCycles(newId);
        }
      },
    },
  },
  async mounted() {
    if (this.actuator && this.actuator.id) {
      this.loadCustomControlCycles(this.actuator.id);
    }
  },
  methods: {
    validateInput() {
      this.validation.name = !!this.form.name.trim();
      this.validation.startTime = !!this.form.start_time.trim();
      this.validation.endTime = !!this.form.end_time.trim();

      switch (this.selectedType) {
        case "setpoint":
          this.validation.setpointPresetId = !!this.form.setpoint_preset_id;
          break;
        case "unitmode":
          this.validation.unitMode = !!this.form.unit_mode;
          break;
        case "customcontrolcycle":
          this.validation.customControlCycle =
            !!this.form.custom_control_cycle_id;
          break;
        case "onoff":
          this.validation.onOff = true; // Always valid as it's a hidden input
          break;
        case "ecomode":
          this.validation.ecoMode = true; // Always valid as it's just a flag
          break;
        case "eco2mode":
          this.validation.eco2Mode = true; // Always valid as it's just a flag
          break;
        case "controlcycle":
          this.validation.controlCycle = true; // Always valid as it's just a flag
          break;
      }

      const isValid = Object.values(this.validation).every((v) => v === true);

      return isValid;
    },
    initializeForm() {
      const form = {
        id: this.schedule?.id || null,
        name: this.schedule?.name || "",
        actuator_class: this.actuator.type,
        actuator_id: this.actuator.id,
        is_recurring: this.isRecurring,
        start_time: this.schedule?.startTime?.slice(0, 5) || "",
        end_time: this.schedule?.endTime?.slice(0, 5) || "",
        dates: this.schedule?.dates || this.selectedDates,
        active: this.schedule?.active ?? true,
        priority:
          this.schedule?.priority || (this.recurrentScheduleSettings ? 1 : 0),
        setpoint_preset_id: this.schedule?.setpointPreset?.id || null,
        on_off: "off",
        unit_mode: this.schedule?.unitMode || "heat",
        content_type: this.selectedType,
        custom_control_cycle_id:
          this.schedule?.custom_control_cycle?.id || null,
        control_cycle: false,
      };
      if (this.selectedType === "ecomode") {
        form.eco_mode = true;
      }
      if (this.selectedType === "eco2mode") {
        form.eco2_mode = true;
      }
      if (this.selectedType === "controlcycle") {
        form.control_cycle = true;
      }
      return form;
    },
    handlePresetSelection(presetId) {
      this.form.setpoint_preset_id = presetId;
    },
    mapDaysOfWeek(days) {
      const dayMapping = this.dayMapping();
      return days.map((day) => dayMapping[day]);
    },
    dayMapping() {
      return {
        Lunes: 0,
        Martes: 1,
        Miércoles: 2,
        Jueves: 3,
        Viernes: 4,
        Sábado: 5,
        Domingo: 6,
      };
    },
    onSave() {
      if (this.validateInput()) {
        let eventName;
        if (this.form.id === null) {
          delete this.form.id;
        }
        if (this.form.id) {
          eventName = "update";
        } else {
          eventName = "create";
        }
        this.$emit(eventName, {
          schedule: this.form,
          scheduleType: this.selectedType,
        });
      }
    },
    onDelete() {
      this.$store.dispatch("deleteSchedule", {
        scheduleType: this.selectedType,
        id: this.form.id,
      });
      this.$emit("delete", { schedule: this.form });
    },
    cancel() {
      this.$emit("cancel", this.form);
    },
    ...mapActions(["loadAllCustomControlCycles", "loadCustomControlCycles"]),
  },
};
</script>
