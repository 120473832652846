<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  @click="closeModal"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <component
                    :is="isEditing ? PencilIcon : PlusIcon"
                    class="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-base font-semibold leading-6 text-gray-900"
                  >
                    {{ isEditing ? "Editar Setpoint" : "Crear nuevo Setpoint" }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Llena el formulario para crear un nuevo grupo de
                      setpoints. El límite inferior indica desde donde se
                      calienta y el límite superior indica desde donde se
                      enfría.
                    </p>
                  </div>
                </div>
              </div>
              <form class="mt-5 sm:mt-6" @submit.prevent="validateAndSubmit">
                <div class="space-y-4">
                  <div class="h-[70px]">
                    <label
                      for="name"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >Nombre</label
                    >
                    <div class="relative mt-2">
                      <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        name="name"
                        :class="[
                          'block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
                          errors.name ? 'ring-red-500' : 'ring-gray-300',
                        ]"
                        placeholder="Nombre del preset"
                      />
                      <span
                        v-if="errors.name"
                        class="absolute mt-1 text-xs text-red-500"
                        >{{
                          typeof errors.name === "string"
                            ? errors.name
                            : "* Este campo es requerido"
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <div class="h-[70px]">
                      <label
                        for="lowTemp"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Límite inferior</label
                      >
                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          id="lowTemp"
                          v-model.number="form.setpointTempLow"
                          type="text"
                          pattern="[0-9]*\.?[0-9]*"
                          name="lowTemp"
                          :class="[
                            'block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
                            errors.setpointTempLow
                              ? 'ring-red-500'
                              : 'ring-gray-300',
                          ]"
                          placeholder="19.5"
                        />
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          <span class="text-gray-500 sm:text-sm">°C</span>
                        </div>
                      </div>
                      <span
                        v-if="errors.setpointTempLow"
                        class="absolute mt-1 text-xs text-red-500"
                        >{{
                          typeof errors.setpointTempLow === "string"
                            ? errors.setpointTempLow
                            : "* Este campo es requerido"
                        }}</span
                      >
                    </div>
                    <div class="h-[70px]">
                      <label
                        for="highTemp"
                        class="block text-sm font-medium leading-6 text-gray-900"
                        >Límite superior</label
                      >
                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          id="highTemp"
                          v-model.number="form.setpointTempHigh"
                          type="text"
                          pattern="[0-9]*\.?[0-9]*"
                          name="highTemp"
                          :class="[
                            'block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
                            errors.setpointTempHigh
                              ? 'ring-red-500'
                              : 'ring-gray-300',
                          ]"
                          placeholder="23.5"
                        />
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          <span class="text-gray-500 sm:text-sm">°C</span>
                        </div>
                      </div>
                      <span
                        v-if="errors.setpointTempHigh"
                        class="absolute mt-1 text-xs text-red-500"
                        >{{
                          typeof errors.setpointTempHigh === "string"
                            ? errors.setpointTempHigh
                            : "* Este campo es requerido"
                        }}</span
                      >
                    </div>
                  </div>
                  <div>
                    <label
                      for="description"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >Descripción</label
                    >
                    <div class="relative mt-2">
                      <textarea
                        id="description"
                        v-model="form.description"
                        name="description"
                        rows="3"
                        :class="[
                          'block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
                          errors.description ? 'ring-red-500' : 'ring-gray-300',
                        ]"
                        placeholder="Descripción del preset"
                      ></textarea>
                      <span
                        v-if="errors.description"
                        class="absolute mt-1 text-xs text-red-500"
                        >{{ errors.description }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
                >
                  <button
                    type="submit"
                    class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                  >
                    {{ isEditing ? "Guardar cambios" : "Crear" }}
                  </button>
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    @click="closeModal"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon, PencilIcon, PlusIcon } from "@heroicons/vue/24/outline";

export default {
  name: "SetpointPresetModal",
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    PencilIcon,
    PlusIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    preset: {
      type: Object,
      default: null,
    },
  },
  emits: ["close", "create", "update"],
  data() {
    return {
      form: {
        name: "",
        setpointTempLow: null,
        setpointTempHigh: null,
        description: "",
      },
      errors: {
        name: false,
        setpointTempLow: false,
        setpointTempHigh: false,
        description: false,
      },
    };
  },
  computed: {
    isEditing() {
      return !!this.preset;
    },
  },
  watch: {
    preset: {
      immediate: true,
      handler(newPreset) {
        if (newPreset) {
          this.form = {
            name: newPreset.name,
            setpointTempLow: newPreset.setpointTempLow,
            setpointTempHigh: newPreset.setpointTempHigh,
            description: newPreset.description,
          };
        } else {
          this.resetForm();
        }
      },
    },
  },
  methods: {
    validateAndSubmit() {
      this.resetErrors();

      if (!this.form.name.trim()) {
        this.errors.name = true;
      } else if (this.form.name.length > 12) {
        this.errors.name = "El nombre no puede exceder 12 caracteres";
      }

      if (this.form.setpointTempLow === null) {
        this.errors.setpointTempLow = true;
      } else if (
        this.form.setpointTempLow <= 14 ||
        this.form.setpointTempLow > 32
      ) {
        this.errors.setpointTempLow =
          "La temperatura debe estar entre 14°C y 32°C";
      }

      if (this.form.setpointTempHigh === null) {
        this.errors.setpointTempHigh = true;
      } else if (
        this.form.setpointTempHigh <= 14 ||
        this.form.setpointTempHigh > 32
      ) {
        this.errors.setpointTempHigh =
          "La temperatura debe estar entre 14°C y 32°C";
      }

      if (
        this.form.setpointTempLow !== null &&
        this.form.setpointTempHigh !== null &&
        this.form.setpointTempLow >= this.form.setpointTempHigh
      ) {
        this.errors.setpointTempLow =
          "La temperatura inferior debe ser menor que la superior";
      }

      if (!this.form.description.trim()) {
        this.errors.description = "Este campo es requerido";
      } else if (this.form.description.length > 200) {
        this.errors.description =
          "La descripción no puede exceder 200 caracteres";
      }

      if (!Object.values(this.errors).some(Boolean)) {
        if (this.isEditing) {
          this.$emit("update", { ...this.form });
        } else {
          this.$emit("create", { ...this.form });
        }
        this.closeModal();
      }
    },
    resetErrors() {
      this.errors = {
        name: false,
        setpointTempLow: false,
        setpointTempHigh: false,
        description: false,
      };
    },
    resetForm() {
      this.form = {
        name: "",
        setpointTempLow: null,
        setpointTempHigh: null,
        description: "",
      };
    },
    closeModal() {
      this.$emit("close");
      this.resetForm();
      this.resetErrors();
    },
  },
};
</script>
