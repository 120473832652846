<template>
  <div
    ref="actuatorRef"
    class="flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow"
    :class="{
      'scale-105 transform border border-blue-500 bg-blue-100': isHighlighted,
    }"
  >
    <div class="flex items-center justify-between p-5">
      <h3 class="font-semibold text-gray-900">
        {{ actuator.name }}
      </h3>
      <div class="flex items-center gap-6">
        <button type="button" class="flex" @click="openScheduleModal">
          <font-awesome-icon
            icon="calendar-days"
            class="h-6 w-6 text-gray-400 hover:text-gray-500"
          />
        </button>
        <button type="button" class="flex" @click="openLogsModal">
          <font-awesome-icon
            icon="list"
            class="h-6 w-6 text-gray-400 hover:text-gray-500"
          />
        </button>
        <button type="button" class="flex" @click="handleMaintenanceMode">
          <font-awesome-icon
            icon="wrench"
            class="h-6 w-6 text-gray-400 hover:text-gray-500"
            :class="{ 'text-yellow-400': !actuator.automaticControl }"
          />
        </button>
        <Switch
          v-model="actuator.unitPower"
          :class="[
            actuator.unitPower ? 'bg-blue-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          ]"
          :disabled="loading"
          @click="userUpdatePending = true"
        >
          <span
            :class="[
              actuator.unitPower ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            ]"
          />
        </Switch>
      </div>
    </div>
    <div class="flex h-64 divide-x">
      <div class="flex grow flex-col divide-y">
        <button
          v-if="actuator.setpointMode === 'manual'"
          class="flex-1 items-center justify-center hover:bg-gray-50 active:bg-gray-100"
          :disabled="loading"
          @click="increaseSetpointTempLow"
        >
          <font-awesome-icon icon="angle-up" class="h-6 w-6 text-gray-400" />
        </button>
        <div class="flex flex-1 flex-col items-center justify-center">
          <h6 class="text-sm text-gray-600">Inferior</h6>
          <h5 class="text-2xl text-gray-800">{{ setpointTempLow }}º</h5>
        </div>
        <button
          v-if="actuator.setpointMode === 'manual'"
          class="flex flex-1 items-center justify-center rounded-bl-lg hover:bg-gray-50 active:bg-gray-100"
          :disabled="loading"
          @click="decreaseSetpointTempLow"
        >
          <font-awesome-icon icon="angle-down" class="h-6 w-6 text-gray-400" />
        </button>
      </div>
      <div class="flex grow flex-col items-center justify-between divide-y">
        <SetpointPresetSelectMenu
          v-if="
            actuator.setpointMode === 'preset' ||
            actuator.setpointMode === 'manual'
          "
          :actuator-index="actuatorIndex"
          @loadingChanged="handleSetpointPresetSelectMenuLoadingChanged"
          @setpointModeChanged="handleSetpointModeChanged"
        />
        <div class="flex h-full w-full flex-col items-center justify-between">
          <div class="flex h-full flex-col items-center justify-center gap-4">
            <div>
              <font-awesome-icon
                v-if="actuator.unitPower && actuator.unitMode === 'cool'"
                icon="snowflake"
                style="--fa-beat-scale: 1.1; --fa-animation-duration: 2s"
                beat
                class="h-10 w-10 text-blue-400"
              />
              <font-awesome-icon
                v-if="actuator.unitPower && actuator.unitMode === 'fan'"
                icon="fan"
                style="--fa-animation-duration: 4s"
                spin
                class="h-10 w-10 text-emerald-400"
              />
              <font-awesome-icon
                v-if="actuator.unitPower && actuator.unitMode === 'heat'"
                icon="fire"
                style="--fa-beat-scale: 1.1; --fa-animation-duration: 2s"
                beat
                class="h-10 w-10 text-amber-400"
              />
            </div>
            <div class="flex flex-col items-center">
              <h5 :class="unitModeHuman.colorClass" class="text-lg font-medium">
                {{ unitModeHuman.text }}
              </h5>
              <h4
                v-if="actuator.ambientTemp !== null"
                class="text-5xl font-light text-gray-800"
              >
                {{ parseFloat(actuator.ambientTemp).toFixed(1) }}º
              </h4>
            </div>
          </div>
          <InfoToolTip
            v-if="
              actuator.controlCycleMode === 'eco' ||
              actuator.controlCycleMode === 'eco_2'
            "
            msg="Modo Eco: Optimiza el consumo de energía manteniendo el confort"
            icon="leaf"
            extra-class="h-5 w-5 text-emerald-400 cursor-pointer"
          />
          <div class="flex h-10 items-center justify-center gap-2">
            <font-awesome-icon
              icon="circle"
              class="h-2 w-2"
              :class="[status.colorClass]"
            />
            <h6 class="text-sm text-gray-600">{{ status.text }}</h6>
          </div>
        </div>
      </div>
      <div class="flex grow flex-col divide-y">
        <button
          v-if="actuator.setpointMode === 'manual'"
          class="justifys-center flex-1 items-center hover:bg-gray-50 active:bg-gray-100"
          :disabled="loading"
          @click="increaseSetpointTempHigh"
        >
          <font-awesome-icon icon="angle-up" class="h-6 w-6 text-gray-400" />
        </button>
        <div class="flex flex-1 flex-col items-center justify-center">
          <h6 class="text-sm text-gray-600">Superior</h6>
          <h5 class="text-2xl text-gray-800">{{ setpointTempHigh }}º</h5>
        </div>
        <button
          v-if="actuator.setpointMode === 'manual'"
          class="flex flex-1 items-center justify-center rounded-br-lg hover:bg-gray-50 active:bg-gray-100"
          :disabled="loading"
          @click="decreaseSetpointTempHigh"
        >
          <font-awesome-icon icon="angle-down" class="h-6 w-6 text-gray-400" />
        </button>
      </div>
    </div>
  </div>
  <MaintenanceModeModal
    :is-open="isMaintenanceModeModalOpen"
    :actuator-id="actuator.id"
    :is-maintenance-active="!actuator.automaticControl"
    @close="closeMaintenanceModeModal"
    @activate="activateMaintenanceMode"
    @deactivate="deactivateMaintenanceMode"
  />
</template>

<script>
import { Switch } from "@headlessui/vue";

import SetpointPresetSelectMenu from "./SetpointPresetSelectMenu.vue";
import MaintenanceModeModal from "./MaintenanceModeModal.vue";
import InfoToolTip from "../cards/InfoToolTip.vue";

const MIN_SETPOINT_TEMP = 0;
const MAX_SETPOINT_TEMP = 40;
const DEBOUNCE_TIME_MS = 1000;

export default {
  name: "ActuatorsGridItem",
  components: {
    SetpointPresetSelectMenu,
    MaintenanceModeModal,
    Switch,
    InfoToolTip,
  },
  props: {
    actuatorIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      userUpdatePending: false,
      isMaintenanceModeModalOpen: false,
      setpointPresetSelectMenuLoading: false,
      debounceTimer: null,
    };
  },
  computed: {
    actuator() {
      return this.$store.state.venueActuators[this.actuatorIndex];
    },
    setpointPreset() {
      return this.$store.state.setpointPresets[
        this.actuator.currentSetpointPresetUuid
      ];
    },
    setpointTempLow() {
      if (this.actuator.setpointMode === "manual") {
        return this.actuator.setpointTempLow;
      } else if (this.actuator.setpointMode === "preset") {
        return this.setpointPreset.setpointTempLow;
      }
      return null;
    },
    setpointTempHigh() {
      if (this.actuator.setpointMode === "manual") {
        return this.actuator.setpointTempHigh;
      } else if (this.actuator.setpointMode === "preset") {
        return this.setpointPreset.setpointTempHigh;
      }
      return null;
    },
    unitModeHuman() {
      if (this.actuator.unitPower) {
        switch (this.actuator.unitMode) {
          case "cool":
            return { text: "Enfriando", colorClass: "text-blue-500" };
          case "fan":
            return { text: "Ventilando", colorClass: "text-emerald-500" };
          case "heat":
            return { text: "Calentando", colorClass: "text-amber-500" };
          default:
            return { text: "", colorClass: "text-gray-500" };
        }
      } else {
        return { text: "Apagado", colorClass: "text-gray-500" };
      }
    },
    status() {
      if (!this.actuator.automaticControl) {
        return { text: "Mantención", colorClass: "text-yellow-400" };
      }
      if (this.loading || this.setpointPresetSelectMenuLoading) {
        return { text: "Actualizando", colorClass: "text-amber-400" };
      }
      if (this.actuator.online) {
        return { text: "Conectado", colorClass: "text-emerald-400" };
      }
      return { text: "Desconectado", colorClass: "text-red-400" };
    },
    isHighlighted() {
      if (this.$store.state.highlightedActuatorId === this.actuator.id) {
        this.$nextTick(() => {
          const element = this.$el;
          const elementRect = element.getBoundingClientRect();
          const absoluteElementTop = elementRect.top + window.pageYOffset;
          const middle = absoluteElementTop - window.innerHeight / 2;
          window.scrollTo({ top: middle, behavior: "smooth" });
        });
      }
      return this.$store.state.highlightedActuatorId === this.actuator.id;
    },
  },
  watch: {
    actuator: {
      deep: true,
      handler: function () {
        if (this.userUpdatePending) {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = setTimeout(
            this.dispatchUpdateActuator,
            DEBOUNCE_TIME_MS
          );
        }
      },
    },
  },
  methods: {
    async dispatchUpdateActuator() {
      this.loading = true;
      this.clearUserUpdatePending();
      let data = {
        unitPower: this.actuator.unitPower,
      };
      if (this.actuator.setpointMode === "manual") {
        data.manualSetpointTempLow = this.actuator.setpointTempLow;
        data.manualSetpointTempHigh = this.actuator.setpointTempHigh;
      }
      await this.$store.dispatch("updateActuator", {
        id: this.actuator.id,
        data: data,
      });
      this.loading = false;
    },
    setUserUpdatePending() {
      this.userUpdatePending = true;
      this.$store.commit("setActuatorUserUpdatePending");
    },
    clearUserUpdatePending() {
      this.userUpdatePending = false;
      this.$store.commit("clearActuatorUserUpdatePending");
    },
    increaseSetpointTempLow() {
      if (this.actuator.setpointMode !== "manual") return;
      if (this.actuator.setpointTempLow < this.actuator.setpointTempHigh) {
        this.setUserUpdatePending();
        this.actuator.setpointTempLow =
          +this.actuator.setpointTempLow.toFixed(1) + 0.2;
        this.actuator.setpointTempLow =
          +this.actuator.setpointTempLow.toFixed(1);
      }
    },
    decreaseSetpointTempLow() {
      if (this.actuator.setpointMode !== "manual") return;
      if (this.actuator.setpointTempLow > MIN_SETPOINT_TEMP) {
        this.setUserUpdatePending();
        this.actuator.setpointTempLow = this.actuator.setpointTempLow - 0.2;
        this.actuator.setpointTempLow =
          +this.actuator.setpointTempLow.toFixed(1);
      }
    },
    increaseSetpointTempHigh() {
      if (this.actuator.setpointMode !== "manual") return;
      if (this.actuator.setpointTempHigh < MAX_SETPOINT_TEMP) {
        this.setUserUpdatePending();
        this.actuator.setpointTempHigh = this.actuator.setpointTempHigh + 0.2;
        this.actuator.setpointTempHigh =
          +this.actuator.setpointTempHigh.toFixed(1);
      }
    },
    decreaseSetpointTempHigh() {
      if (this.actuator.setpointMode !== "manual") return;
      if (this.actuator.setpointTempHigh > this.actuator.setpointTempLow) {
        this.setUserUpdatePending();
        this.actuator.setpointTempHigh = this.actuator.setpointTempHigh - 0.2;
        this.actuator.setpointTempHigh =
          +this.actuator.setpointTempHigh.toFixed(1);
      }
    },
    openLogsModal() {
      this.$store.commit("setSelectedActuatorId", this.actuator.id);
      this.$store.commit("openActuatorLogsModal");
    },
    openScheduleModal() {
      this.$store.commit("setSelectedActuatorId", this.actuator.id);
      this.$store.commit("openActuatorScheduleModal");
    },
    handleSetpointPresetSelectMenuLoadingChanged(newValue) {
      this.setpointPresetSelectMenuLoading = newValue;
    },
    handleSetpointModeChanged(newValue) {
      this.setUserUpdatePending();
      this.actuator.setpointMode = newValue;
    },
    openMaintenanceModeModal() {
      this.isMaintenanceModeModalOpen = true;
    },
    closeMaintenanceModeModal() {
      this.isMaintenanceModeModalOpen = false;
    },
    activateMaintenanceMode({ actuatorId, duration }) {
      this.loading = true;
      this.clearUserUpdatePending();
      const data = { delay: duration };
      this.$store.dispatch("activateMaintenanceMode", {
        id: actuatorId,
        data: data,
      });
      this.loading = false;
    },
    handleMaintenanceMode() {
      this.openMaintenanceModeModal();
    },
    deactivateMaintenanceMode(actuatorId) {
      this.loading = true;
      this.clearUserUpdatePending();
      this.$store
        .dispatch("deactivateMaintenanceMode", actuatorId)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
