<template>
  <TransitionRoot as="template" :show="modalOpen">
    <Dialog as="div" class="relative z-10" @close="$emit('closeModal')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <form @submit.prevent="sendPasswordResetMail">
                <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div
                    v-if="success !== null"
                    class="mb-5 rounded-md p-4"
                    :class="{
                      'bg-green-50 text-green-800': success,
                      'bg-yellow-50 text-yellow-800': !success,
                    }"
                  >
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <CheckCircleIcon
                          v-if="success"
                          class="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                        <ExclamationTriangleIcon
                          v-else
                          class="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3">
                        <p class="text-sm font-medium">
                          {{
                            success
                              ? "Correo enviado"
                              : "Por favor vuelve a intentarlo"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div
                      class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                      <KeyIcon
                        class="h-6 w-6 text-flairblue"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle
                        as="h3"
                        class="text-base font-semibold leading-6 text-gray-900"
                        >Restablece tu contraseña</DialogTitle
                      >
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Si tu correo electrónico está inscrito en nuestra
                          plataforma, te enviaremos las instrucciones a este.
                        </p>
                      </div>
                      <div class="mt-3">
                        <div>
                          <label for="email" class="sr-only">Email</label>
                          <input
                            id="email-password-reset"
                            v-model="email"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required="true"
                            class="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-flairblue sm:text-sm sm:leading-6"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                >
                  <button
                    type="submit"
                    class="inline-flex w-full justify-center rounded-md bg-flairblue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 sm:ml-3 sm:w-auto"
                  >
                    Restablecer
                  </button>
                  <button
                    ref="cancelButtonRef"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    @click="$emit('closeModal')"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { KeyIcon } from "@heroicons/vue/24/outline";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/vue/20/solid";

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    KeyIcon,
    CheckCircleIcon,
    ExclamationTriangleIcon,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeModal"],
  data() {
    return {
      email: "",
      success: null,
    };
  },
  methods: {
    async sendPasswordResetMail() {
      await this.$store
        .dispatch("resetPassword", this.email)
        .then(() => {
          this.success = true;
          setTimeout(() => {
            this.$emit("closeModal");
            setTimeout(() => {
              this.email = "";
              this.success = null;
            }, 500);
          }, 2000);
        })
        .catch(() => {
          this.success = false;
        });
    },
  },
};
</script>
