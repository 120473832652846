<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10" @close="closeModal">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogPanel
              class="mx-auto max-h-[600px] min-h-[600px] w-full max-w-4xl transform overflow-scroll rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all sm:max-w-4xl"
            >
              <button
                type="button"
                class="absolute right-0 top-0 p-4"
                @click="closeModal"
              >
                <font-awesome-icon
                  icon="xmark"
                  class="h-6 w-6 text-gray-400 hover:text-gray-500"
                />
              </button>
              <div
                class="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200"
              >
                <div class="flex flex-col items-center space-y-6 px-4 py-6">
                  <div>
                    <h3 class="text-lg font-semibold text-gray-900">
                      Programar Actuador
                    </h3>
                    <p class="text-sm text-gray-500">
                      Programa el actuador para que se active en un horario
                      específico o de forma recurrente.
                    </p>
                  </div>
                  <div class="mb-4 flex w-full items-center justify-between">
                    <button class="p-2" @click="switchView">
                      <font-awesome-icon
                        icon="angle-left"
                        class="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    <div class="text-sm font-semibold">
                      {{ isRecurring ? "Recurrente" : "Específica" }}
                    </div>
                    <button class="p-2" @click="switchView">
                      <font-awesome-icon
                        icon="angle-right"
                        class="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>

                  <component
                    :is="views[currentViewIndex]"
                    :key="views[currentViewIndex]"
                    :selected-dates="selectedDates"
                    :recurrent-schedule-settings="recurrentScheduleSettings"
                    @update:selected-dates="handleSelectedDates"
                    @update:recurrent-schedule-settings="handleUpdateSettings"
                  />
                </div>
                <div class="p-5">
                  <ScheduleList
                    :actuator="actuator"
                    :is-recurring="isRecurring"
                    :selected-dates="selectedDates"
                    :recurrent-schedule-settings="recurrentScheduleSettings"
                    :schedule-type="scheduleType"
                    @schedule-selected="handleScheduleSelected"
                    @switching-form="handleSwitchingForm"
                  />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";

import ScheduleCalendar from "./schedule/ScheduleCalendar.vue";
import DayOfWeekPicker from "./schedule/DayOfWeekPicker.vue";
import ScheduleList from "./schedule/ScheduleList.vue";

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionRoot,
    TransitionChild,
    ScheduleCalendar,
    DayOfWeekPicker,
    ScheduleList,
  },
  data() {
    return {
      views: ["ScheduleCalendar", "DayOfWeekPicker"],
      currentViewIndex: 0,
      selectedDates: [],
      selectedDays: [],
      scheduleType: "setpoint",
      recurrentScheduleSettings: {
        selectedDays: [],
        frequency: 1,
        intervalUnit: "weeks",
        endDate: this.tomorrowDate,
      },
    };
  },
  computed: {
    isOpen() {
      return this.$store.state.isActuatorScheduleModalOpen;
    },
    actuator() {
      return this.$store.state.venueActuators.find(
        (actuator) => actuator.id === this.$store.state.selectedActuatorId
      );
    },
    isRecurring() {
      return this.currentViewIndex === this.views.indexOf("DayOfWeekPicker");
    },
    tomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().split("T")[0];
    },
  },
  methods: {
    switchView() {
      this.selectedDates = [];
      this.recurrentScheduleSettings = {
        selectedDays: [],
        frequency: 1,
        intervalUnit: "weeks",
        endDate: this.tomorrowDate,
      };
      this.currentViewIndex = (this.currentViewIndex + 1) % this.views.length;
    },
    handleSwitchingForm() {
      this.selectedDates = [];
      this.recurrentScheduleSettings = {
        selectedDays: [],
        frequency: 1,
        intervalUnit: "weeks",
        endDate: this.tomorrowDate,
      };
    },
    handleSelectedDates(selectedDates) {
      this.selectedDates = selectedDates;
    },
    handleUpdateSettings(settings) {
      this.recurrentScheduleSettings = settings;
    },
    handleScheduleSelected(schedule) {
      if (this.isRecurring) {
        this.currentViewIndex = this.views.indexOf("DayOfWeekPicker");
        this.recurrentScheduleSettings = {
          selectedDays: this.parseDaysOfWeek(schedule.daysOfWeek),
          frequency: schedule.frequency,
          intervalUnit: schedule.intervalUnit,
          endDate: schedule.endDate,
        };
      } else {
        this.selectedDate = schedule.startDate;
        this.selectedDates = schedule.dates;
        this.currentViewIndex = this.views.indexOf("ScheduleCalendar");
      }
    },
    parseDaysOfWeek(daysOfWeek) {
      return daysOfWeek.map((day) => {
        return [
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
          "Domingo",
        ][day];
      });
    },
    closeModal() {
      this.selectedDates = [];
      this.recurrentScheduleSettings = {
        selectedDays: [],
        frequency: 1,
        intervalUnit: "weeks",
        endDate: this.tomorrowDate,
      };
      this.$store.commit("closeActuatorScheduleModal");
    },
  },
};
</script>
