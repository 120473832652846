<template>
  <Listbox
    v-model="selectedOption"
    as="div"
    class="flex w-full"
    :disabled="loading"
  >
    <ListboxLabel class="sr-only">Change setpoint preset</ListboxLabel>
    <div class="relative w-full">
      <ListboxButton
        :class="[
          'col-span-2 mt-1 flex w-full justify-between rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm sm:text-sm',
          validation
            ? 'ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600'
            : 'ring-2 ring-red-500 focus:ring-2 focus:ring-red-600',
        ]"
      >
        <span class="sr-only">Change setpoint preset</span>
        <p class="text-sm font-semibold text-gray-800">
          {{ selectedOption.name }}
        </p>
        <div class="flex">
          <p v-if="hasMadeSelection" class="text-gray-900">
            ({{ selectedOption.setpointTempLow }}º -
            {{ selectedOption.setpointTempHigh }}º)
          </p>
          <font-awesome-icon
            icon="angle-down"
            class="h-5 w-5 text-gray-600 hover:text-gray-800"
            aria-hidden="true"
          />
        </div>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute right-0 z-10 mt-2 max-h-40 w-72 origin-top-right divide-y divide-gray-200 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <ListboxOption
            v-for="option in setpointPresetOptions"
            :key="option.name"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
          >
            <li
              :class="[
                active ? 'bg-blue-600 text-white' : 'text-gray-900',
                'cursor-default select-none p-4 text-sm',
              ]"
            >
              <div class="flex flex-col gap-2">
                <div class="flex justify-between">
                  <p :class="selected ? 'font-semibold' : 'font-normal'">
                    {{ option.name }} ({{ option.setpointTempLow }}º -
                    {{ option.setpointTempHigh }}º)
                  </p>
                  <span
                    v-if="selected"
                    :class="active ? 'text-white' : 'text-blue-600'"
                  >
                    <font-awesome-icon
                      icon="check"
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <p
                  v-if="option.description"
                  :class="[active ? 'text-blue-200' : 'text-gray-500']"
                >
                  {{ option.description }}
                </p>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

export default {
  name: "SetpointPresetDropdown",
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
  },
  props: {
    actuator: {
      type: Object,
      required: true,
    },
    schedulePresetId: {
      type: String,
      default: null,
    },
    validation: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["loadingChanged", "update:selectedPresetId"],
  data() {
    return {
      selectedPresetId: null,
      loading: false,
      hasMadeSelection: false,
    };
  },
  computed: {
    setpointPresets() {
      return this.$store.state.setpointPresets;
    },
    setpointPresetOptions() {
      const currentPresetUuid =
        this.schedulePresetId || this.actuator.currentSetpointPresetUuid;
      const currentPreset = this.setpointPresets[currentPresetUuid];

      let options = this.actuator.availableSetpointPresetUuids
        .filter((id) => id !== currentPresetUuid)
        .map((id) => this.setpointPresets[id])
        .sort((a, b) => a.name.localeCompare(b.name));

      if (currentPreset) {
        options.unshift(currentPreset);
      }

      return options;
    },
    selectedOption: {
      get() {
        if (this.hasMadeSelection) {
          return (
            this.setpointPresets[this.selectedPresetId] || {
              name: "Seleccionar",
            }
          );
        } else if (this.schedulePresetId) {
          return this.setpointPresets[this.schedulePresetId];
        } else {
          return { name: "Seleccionar" };
        }
      },
      set(preset) {
        this.selectedPresetId = preset.id;
        this.hasMadeSelection = true;
        this.$emit("update:selectedPresetId", preset.id);
      },
    },
  },
  watch: {
    loading(newValue) {
      this.$emit("loadingChanged", newValue);
    },
  },
  methods: {
    updateCurrentSetpointPresetUuid(presetUuid) {
      if (this.actuator.currentSetpointPresetUuid === presetUuid) {
        return;
      }
      this.$emit("update:selectedPresetId", presetUuid);
    },
  },
};
</script>
