import "./index.css";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import { BrowserTracing } from "@sentry/tracing";
import { createApp } from "vue";
import router from "./router";
import store from "./store";
import tailwindConfig from "../tailwind.config";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBars,
  faBiohazard,
  faBolt,
  faCalendarAlt,
  faCalendarDays,
  faCheck,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faClock,
  faCogs,
  faCog,
  faCommentDots,
  faCrown,
  faDroplet,
  faEllipsis,
  faEllipsisV,
  faEnvelope,
  faFaceAngry,
  faFaceFrown,
  faFaceLaughBeam,
  faFaceMeh,
  faFaceSmile,
  faFan,
  faFile,
  faFire,
  faFlask,
  faHeartbeat,
  faInbox,
  faInfo,
  faLeaf,
  faList,
  faListCheck,
  faMagnifyingGlass,
  faPaperPlane,
  faPhone,
  faPlug,
  faPlugCircleCheck,
  faPlugCircleXmark,
  faPowerOff,
  faQuestion,
  faRectangleList,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faSignOutAlt,
  faSmog,
  faSnowflake,
  faSpinner,
  faSync,
  faTachometerAlt,
  faTemperatureArrowDown,
  faTemperatureArrowUp,
  faTemperatureHalf,
  faTimes,
  faToilet,
  faUtensils,
  faUser,
  faUserPen,
  faUsersCog,
  faUserSlash,
  faVolumeDown,
  faVolumeOff,
  faVolumeUp,
  faWrench,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBars,
  faBiohazard,
  faBolt,
  faCalendarAlt,
  faCalendarDays,
  faCheck,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faClock,
  faCogs,
  faCog,
  faCommentDots,
  faCrown,
  faDroplet,
  faEllipsis,
  faEllipsisV,
  faEnvelope,
  faFaceAngry,
  faFaceFrown,
  faFaceLaughBeam,
  faFaceMeh,
  faFaceSmile,
  faFan,
  faFile,
  faFire,
  faFlask,
  faHeartbeat,
  faInbox,
  faInfo,
  faLeaf,
  faList,
  faListCheck,
  faMagnifyingGlass,
  faPaperPlane,
  faPhone,
  faPlug,
  faPlugCircleCheck,
  faPlugCircleXmark,
  faPowerOff,
  faQuestion,
  faRectangleList,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faSignOutAlt,
  faSmog,
  faSnowflake,
  faSpinner,
  faSync,
  faTachometerAlt,
  faTemperatureArrowDown,
  faTemperatureArrowUp,
  faTemperatureHalf,
  faTimes,
  faToilet,
  faUtensils,
  faUser,
  faUserPen,
  faUsersCog,
  faUserSlash,
  faVolumeDown,
  faVolumeOff,
  faVolumeUp,
  faWrench,
  faXmark
);

const app = createApp(App)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon);

app.config.globalProperties.colors = tailwindConfig.theme.extend.colors;
app.config.globalProperties.setOpacity = (hex, alpha) =>
  `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, 0)}`;

app.config.errorHandler = (err) => {
  throw err;
};

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  tracesSampleRate: 1.0,
});

app.mount("#app");
