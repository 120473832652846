<template>
  <modal
    modal-class="add-user-modal self-center"
    @close-modal="$emit('close-modal')"
  >
    <template #header>
      <div class="pt-2 text-3xl font-medium">Crear nuevo usuario</div>
    </template>
    <template #body>
      <form class="flex flex-col justify-center gap-3" @submit.prevent>
        <text-field
          id="email"
          v-model="email"
          label="Email de usuario"
          placeholder="ejemplo@correo.com"
          type="text"
          :validator="validator"
        />

        <div v-if="isSuperuser" class="pb-4">
          <label class="mb-2 block text-sm font-bold text-gray-700">
            Tipo de usuario
          </label>
          <div>
            <radio-button-group
              v-model="userType"
              :options="options"
              @input="updateUserType"
            />
          </div>
        </div>
        <div class="flex items-center justify-center pb-4">
          <label class="px-4 text-sm font-bold text-gray-700">
            Requerir 2FA
          </label>
          <Switch
            v-model="require2FA"
            :class="[
              require2FA ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
            ]"
          >
            <span class="sr-only">Requerir 2FA</span>
            <span
              :class="[
                require2FA ? 'translate-x-6' : 'translate-x-1',
                'inline-block h-4 w-4 transform rounded-full bg-white transition',
              ]"
            />
          </Switch>
        </div>
        <div>
          <label class="mb-2 block text-sm font-bold text-gray-700">
            Selección de locaciones
          </label>

          <multiselect
            v-model="selectedVenues"
            track-by="name"
            :options="venues"
            multiple
            :close-on-select="false"
            :clear-on-select="false"
            preserve-search
            placeholder="Elige una o más locaciones"
            :custom-label="customLabel"
            :show-labels="false"
            :limit="4"
            :max-height="176"
            :limit-text="(count) => `y ${count} más`"
            group-select
            hide-selected
          >
            <template #beforeList>
              <div class="multiselect__tags">
                <span
                  v-if="selectedVenues.length === venues.length"
                  class="cursor-pointer pl-1 hover:text-gray-800"
                  @click="selectedVenues = []"
                >
                  Quitar todos
                </span>
                <span
                  v-else
                  class="cursor-pointer pl-1 hover:text-gray-800"
                  @click="selectedVenues = venues.slice()"
                >
                  Seleccionar todas
                </span>
              </div>
            </template>
            <template #noResult> No existen coincidencias </template>
          </multiselect>
        </div>
        <div
          v-if="Boolean(errorMsg)"
          class="flex justify-center font-bold text-red-500"
        >
          {{ errorMsg }}
        </div>
      </form>
    </template>
    <template #footer>
      <tri-state-button
        id="button"
        :funct="createUser"
        label="Crear Usuario"
        :disabled="invalidForm"
        :state="buttonState"
      />
    </template>
  </modal>
</template>

<script>
import Modal from "../cards/Modal.vue";
import Multiselect from "vue-multiselect";
import RadioButtonGroup from "../RadioButtonGroup.vue";
import TextField from "../TextField.vue";
import TriStateButton from "../common/TriStateButton.vue";
import { listenerMixin } from "../../mixins";
import { validateEmail } from "../../utils";
import { Switch } from "@headlessui/vue";

export default {
  name: "AddUser",
  components: {
    TextField,
    TriStateButton,
    Multiselect,
    RadioButtonGroup,
    Modal,
    Switch,
  },
  mixins: [listenerMixin],
  emits: ["close-modal"],
  data() {
    return {
      email: "",
      errorMsg: "",
      buttonState: "default",
      selectedVenues: [],
      userType: "",
      inside: false,
      require2FA: true,
      options: [
        { key: "is_superuser", label: "Super usuario" },
        { key: "is_manager", label: "Manager" },
        { key: "is_user", label: "Usuario" },
      ],
    };
  },
  computed: {
    venues() {
      return this.$store.state.venues;
    },
    invalidForm() {
      return !(this.validMail && this.validVenueArray);
    },
    validVenueArray() {
      return this.selectedVenues.length > 0;
    },
    validMail() {
      return validateEmail(this.email);
    },
    validator() {
      return validateEmail;
    },
    formParams() {
      const { email, selectedVenues, userType } = this;
      return { email, selectedVenues, userType };
    },
    isSuperuser() {
      return this.$store.state.user.isSuperuser;
    },
  },
  watch: {
    formParams: {
      deep: true,
      handler: "enableButton",
    },
  },
  methods: {
    updateUserType(option) {
      this.userType = option;
    },
    customLabel(venues) {
      return `${venues.name}`;
    },
    enableButton() {
      if (this.buttonState == "success" || this.buttonState == "error") {
        this.buttonState = "default";
        this.errorMsg = "";
        this.selectedVenues = [];
      }
    },
    async createUser() {
      if (this.validVenueArray) {
        const payload = {
          email: this.email,
          venues: this.selectedVenues.map((x) => x["id"]),
          user_type: this.userType,
          require_2fa: this.require2FA,
        };
        this.$store
          .dispatch("createUser", payload)
          .then(() => {
            this.buttonState = "success";
          })
          .catch(({ response: { status } }) => {
            switch (status) {
              case 409:
                this.errorMsg = "Este usuario ya existe";
                break;
              default:
                this.errorMsg = "Error de conexión";
                break;
            }
            this.buttonState = "error";
          });
      } else {
        this.errorMsg = "Debes seleccionar al menos una locación";
        this.buttonState = "error";
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect {
  width: 100%;
  color: #9ca3af;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 0.25rem;
  flex-shrink: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: default;
  font-size: 15px;
  touch-action: manipulation;
}
.multiselect__placeholder {
  line-height: 1.25;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 2px 3px;
  color: #9ca3af;
}
.multiselect__select {
  padding: 2px 3px;
  display: block;
}
.multiselect:focus {
  outline: none;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.multiselect__input::placeholder {
  color: #9ca3af;
}
.multiselect__tags {
  border-radius: 0;
  font-size: 15px;
  border-bottom-width: 0.5px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tag {
  background: #4a75f1;
}
.multiselect__option--highlight {
  background: #fff;
  color: #301610;
}
.multiselect__option--highlight::after {
  background: #fff;
  color: #301610;
}
.multiselect__content-wrapper {
  position: relative;
  display: block;
  max-height: 220px;
}
.multiselect__content-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: #f5f5f5;
}
.multiselect__content-wrapper::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
.multiselect__content-wrapper::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  background-color: #9ca3af;
}
.add-user-modal {
  text-align: center;
  background-color: white;
  height: fit-content;
  width: 500px;
  margin: 0%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: block;
}
</style>
