import client from "./client";

export default {
  getDailyAccumulatedKwhForVenue: (venueId, target_day) =>
    client.get(
      `venues/${venueId}/energy_meters/daily_accumulated_kwh?target_day=${target_day}`
    ),
  getMonthlyAccumulatedKwhForVenue: (venueId, target_month) =>
    client.get(
      `venues/${venueId}/energy_meters/monthly_accumulated_kwh?target_month=${target_month}`
    ),
  getDailyAverageKwhForVenue: (venueId, target_day) =>
    client.get(
      `venues/${venueId}/energy_meters/daily_average?target_day=${target_day}`
    ),
};
