<template>
  <div class="mb-4">
    <label :for="id" class="mb-2 block text-sm font-bold text-gray-700">
      {{ label }}
    </label>
    <input
      :id="id"
      v-model="inputValue"
      :placeholder="placeholder"
      class="focus:shadow-outline w-full rounded border px-3 py-2 leading-tight text-gray-700 shadow outline-none"
      :class="{ 'border-red-500': modelValue && !valueIsValid }"
      :type="type"
    />
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: true,
    },
    validator: {
      type: Function,
      default: () => true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    valueIsValid() {
      return this.validator(this.inputValue);
    },
  },
};
</script>
