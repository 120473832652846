<template>
  <label :for="option">
    <input
      :id="option"
      type="radio"
      name="radio-input"
      :value="option.key"
      class="w-0.4 pt-6"
      @change="$parent.$emit('input', option.key)"
    />
    {{ option.label }}
  </label>
</template>
<script>
export default {
  name: "RadioButton",
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
};
</script>
