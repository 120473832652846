<template>
  <TransitionRoot as="template" :show="isSlideOverOpen">
    <Dialog as="div" class="relative z-50" @close="closeFeedbackSlideOver">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form
                  class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                  @submit.prevent="submitFeedback"
                >
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-blue-700 px-4 py-6 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle
                          class="text-base font-semibold leading-6 text-white"
                          >Feedback</DialogTitle
                        >
                        <div class="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            class="relative rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            @click="closeFeedbackSlideOver"
                          >
                            <span class="absolute -inset-2.5" />
                            <span class="sr-only">Cerrar panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div class="mt-1">
                        <p class="text-sm text-blue-300">
                          Incluye tus comentarios sobre la plataforma para poder
                          mejorar tu experiencia.
                        </p>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pb-5 pt-5">
                          <RadioGroup v-model="selectedSatisfaction">
                            <RadioGroupLabel
                              class="block text-sm font-medium leading-6 text-gray-900"
                              >Nivel de satisfacción con la
                              plataforma</RadioGroupLabel
                            >
                            <div class="mt-4 flex items-center space-x-3">
                              <RadioGroupOption
                                v-for="level in satisfactionLevels"
                                :key="level.name"
                                v-slot="{ active, checked }"
                                as="template"
                                :value="level.name"
                              >
                                <div
                                  :class="[
                                    level.selectedColor,
                                    active && checked
                                      ? 'ring ring-offset-1'
                                      : '',
                                    !active && checked ? 'ring-2' : '',
                                    'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none',
                                  ]"
                                >
                                  <RadioGroupLabel as="span" class="sr-only">{{
                                    level.name
                                  }}</RadioGroupLabel>
                                  <font-awesome-icon
                                    :icon="level.icon"
                                    aria-hidden="true"
                                    :class="[level.colorClass, 'h-8 w-8']"
                                  />
                                </div>
                              </RadioGroupOption>
                            </div>
                          </RadioGroup>
                        </div>
                        <div class="space-y-6 pb-5 pt-6">
                          <div>
                            <label
                              for="feedback"
                              class="block text-sm font-medium leading-6 text-gray-900"
                              >Feedback</label
                            >
                            <div class="mt-2">
                              <textarea
                                id="feedback"
                                v-model="feedbackText"
                                name="feedback"
                                rows="4"
                                class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                @input="validationMessage = ''"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="space-y-6 pb-5 pt-5">
                          <fieldset>
                            <legend class="sr-only">Anonimato</legend>
                            <div class="space-y-5">
                              <div class="relative flex items-start">
                                <div class="flex h-6 items-center">
                                  <input
                                    id="anonimous"
                                    v-model="isAnonymous"
                                    aria-describedby="anonimous-description"
                                    name="anonimous"
                                    type="checkbox"
                                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                  />
                                </div>
                                <div class="ml-3 text-sm leading-6">
                                  <label
                                    for="anonimous"
                                    class="font-medium text-gray-900"
                                    >Anónimo</label
                                  >
                                  <p
                                    id="anonimous-description"
                                    class="text-gray-500"
                                  >
                                    Quiero que mi feedback sea anónimo.
                                  </p>
                                </div>
                              </div>
                              <p
                                v-if="validationMessage"
                                class="mt-2 text-sm text-red-600"
                              >
                                {{ validationMessage }}
                              </p>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      @click="closeFeedbackSlideOver"
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      :class="[
                        'ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                        feedbackSubmitted
                          ? 'bg-green-200 text-green-800'
                          : 'bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600',
                      ]"
                    >
                      <font-awesome-icon
                        :icon="
                          feedbackSubmitted ? 'circle-check' : 'paper-plane'
                        "
                        class="mr-2 mt-1 h-3 w-3"
                      />
                      {{ feedbackSubmitted ? "Enviado" : "Enviar" }}
                    </button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
} from "@headlessui/vue";

import { XMarkIcon } from "@heroicons/vue/24/outline";

export default {
  name: "FeedbackSlideOver",
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
  data() {
    return {
      feedbackSubmitted: false,
      validationMessage: "",
      feedbackText: "",
      isAnonymous: false,
      selectedSatisfaction: null,
      satisfactionLevels: [
        {
          name: "VERY_DISSATISFIED",
          icon: "face-angry",
          selectedColor: "ring-red-500",
          colorClass: "text-red-500",
        },
        {
          name: "DISSATISFIED",
          icon: "face-frown",
          selectedColor: "ring-orange-500",
          colorClass: "text-orange-500",
        },
        {
          name: "NEUTRAL",
          icon: "face-meh",
          selectedColor: "ring-yellow-500",
          colorClass: "text-yellow-500",
        },
        {
          name: "SATISFIED",
          icon: "face-smile",
          selectedColor: "ring-green-500",
          colorClass: "text-green-500",
        },
        {
          name: "VERY_SATISFIED",
          icon: "face-laugh-beam",
          selectedColor: "ring-green-700",
          colorClass: "text-green-700",
        },
      ],
    };
  },
  computed: {
    isSlideOverOpen() {
      return this.$store.state.isFeedbackSlideOverOpen;
    },
  },
  watch: {
    selectedSatisfaction() {
      this.validationMessage = "";
    },
  },
  methods: {
    closeFeedbackSlideOver() {
      this.$store.commit("closeFeedbackSlideOver");
    },
    submitFeedback() {
      if (!this.feedbackText.trim() && !this.selectedSatisfaction) {
        this.validationMessage =
          "* Debes seleccionar un nivel de satisfacción o escribir un comentario.";
        return;
      }
      this.$store
        .dispatch("submitFeedback", {
          feedback_text: this.feedbackText,
          is_anonymous: this.isAnonymous,
          satisfaction_level: this.selectedSatisfaction,
        })
        .then(() => {
          this.feedbackSubmitted = true;
          setTimeout(() => {
            this.closeFeedbackSlideOver();
            this.resetForm();
          }, 2000);
        });
    },
    resetForm() {
      this.feedbackText = "";
      this.isAnonymous = false;
      this.selectedSatisfaction = null;
      setTimeout(() => {
        this.feedbackSubmitted = false;
      }, 500);
    },
  },
};
</script>
