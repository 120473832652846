<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
  >
    <reset-password :modal-open="modalOpen" @closeModal="closeModal" />
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img
        class="mx-auto w-auto max-w-full"
        src="../../assets/logo.png"
        alt="Flair Chile logo"
      />
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" @submit.prevent="submitForm">
        <div v-if="!showTwoFactorInput">
          <label
            for="email"
            class="block text-left text-sm font-medium leading-6 text-gray-900"
            >Email</label
          >
          <div class="mt-2">
            <input
              id="email"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-flairblue sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div v-if="!showTwoFactorInput">
          <div class="flex items-center justify-between">
            <label
              for="password"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Contraseña</label
            >
            <div class="text-sm">
              <a
                href="#"
                class="font-semibold text-flairblue hover:text-blue-700"
                @click.prevent="openModal"
                >¿Olvidaste tu contraseña?</a
              >
            </div>
          </div>
          <div class="mt-2">
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-flairblue sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div v-if="showTwoFactorInput">
          <label
            for="twoFactorCode"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Código de verificación</label
          >
          <input
            id="twoFactorCode"
            v-model="twoFactorCode"
            type="text"
            required
            class="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-flairblue sm:text-sm sm:leading-6"
          />
        </div>

        <div>
          <button
            type="submit"
            class="flex w-full justify-center rounded-md bg-flairblue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-flairblue"
          >
            {{ showTwoFactorInput ? "Verificar" : "Iniciar sesión" }}
          </button>
        </div>
        <div class="flex h-10 flex-col items-center">
          <font-awesome-icon
            v-if="isLoading"
            icon="spinner"
            style="--fa-animation-duration: 1s"
            spin
            class="h-8 w-8 text-flairblue"
          />
          <span
            v-else-if="Boolean(errorMessage)"
            class="font-bold text-red-500"
            >{{ errorMessage }}</span
          >
          <span v-else>&nbsp;</span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ResetPassword from "./ResetPassword.vue";

export default {
  name: "LoginForm",

  components: {
    ResetPassword,
  },

  data() {
    return {
      modalOpen: false,
      email: "",
      password: "",
      twoFactorCode: "",
      errorMessage: "",
      isLoading: false,
      showTwoFactorInput: false,
      userId: null,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.keyDownHandler);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  methods: {
    keyDownHandler(e) {
      if (e.key === "Escape") this.closeModal();
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    async submitForm() {
      this.isLoading = true;
      this.errorMessage = "";

      try {
        if (this.showTwoFactorInput) {
          await this.verify2FA();
        } else {
          await this.login();
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async login() {
      this.isLoading = true;
      const response = await this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });
      if (response.requires2fa) {
        this.showTwoFactorInput = true;
        this.userId = response.id;
      }
    },
    async verify2FA() {
      await this.$store.dispatch("verify2FA", {
        id: this.userId,
        token: this.twoFactorCode,
      });
    },
    handleError(error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            this.errorMessage = "Credenciales inválidas";
            break;
          case 403:
            this.errorMessage = "Acceso prohibido";
            break;
          default:
            this.errorMessage = "Por favor vuelve a intentarlo";
            break;
        }
      } else {
        this.errorMessage = "Error de conexión. Por favor, inténtelo de nuevo.";
      }
    },
  },
};
</script>
