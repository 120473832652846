<template>
  <div class="flex h-full w-full justify-center rounded-full">
    <div
      class="legend flex h-full w-full flex-col-reverse items-center justify-center rounded-full"
    >
      <div
        v-for="(level, index) in levels"
        :key="level.label"
        class="grid w-full grid-cols-3 gap-4 text-sm"
        :class="[index == 2 ? 'h-3/2' : 'h-full']"
      >
        <p
          class="flex justify-center"
          :class="{ 'opacity-0': index === levels.length - 1 }"
        >
          {{ humidityLevels[index] }}%
        </p>
        <div
          class="flex h-full h-full w-full w-full items-center justify-center bg-gray-300 text-center text-lg font-semibold leading-none text-white"
          :class="[
            level.color,
            {
              hidden: brief && !level.brief,
              'rounded-t-lg': index === levels.length - 1,
              'rounded-b-lg': index === 0,
            },
          ]"
        >
          {{ level.label }}
        </div>
        <p :class="{ 'opacity-0': index === levels.length - 1 }">
          {{ index !== levels.length - 1 ? temperatureLevels[index] : "" }}ºC
        </p>
      </div>
      <div class="grid w-full grid-cols-3 gap-4 font-semibold">
        <p>H%</p>
        <p />
        <p>Tº</p>
      </div>
    </div>
  </div>
</template>

<script>
import { humidityLevels, temperatureLevels } from "../../utils";

export default {
  name: "VerticalLegend",
  props: {
    brief: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      humidityLevels,
      temperatureLevels,
      levels: [
        { color: "bg-blue-900", label: "-", brief: true },
        { color: "bg-blue-500", label: "", brief: true },
        { color: "bg-blue-200", label: "" },
        { color: "bg-blue-500", label: "" },
        { color: "bg-blue-900", label: "+", brief: true },
      ],
    };
  },
};
</script>
