<template>
  <div>
    <logged v-if="isLoggedIn" />
    <login v-else />
  </div>
</template>

<script>
import "moment/locale/es";
import Logged from "./pages/Logged.vue";
import Login from "./pages/Login.vue";
import moment from "moment";

export default {
  name: "App",

  components: {
    Logged,
    Login,
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  async created() {
    this.$store.dispatch("restoreSession");
    moment.locale("es");
  },
};
</script>
