<template>
  <div class="w-full">
    <radio-button
      v-for="option in options"
      :key="option.key"
      :option="option"
      class="g-x-6 flex-auto pr-4"
    />
  </div>
</template>
<script>
import RadioButton from "./RadioButton.vue";

export default {
  name: "RadioButtonGroup",
  components: {
    RadioButton,
  },
  props: {
    options: {
      required: true,
      type: Array,
    },
  },
};
</script>
