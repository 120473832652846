import { camelizeKeys, decamelizeKeys } from "humps";
import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import store from "@/store";

const tokenClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,

  timeout: process.env.VUE_APP_API_TIMEOUT,

  headers: {
    "Content-Type": "application/json",
  },

  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => camelizeKeys(data),
  ],

  transformRequest: [
    (data) => decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
});

const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,

  timeout: process.env.VUE_APP_API_TIMEOUT,

  headers: {
    "Content-Type": "application/json",
  },

  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => camelizeKeys(data),
  ],

  transformRequest: [
    (data) => decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
});
const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  try {
    if (jwtDecode(refreshToken).exp < moment().unix()) {
      throw new Error();
    } else {
      const response = await tokenClient.post("/refresh", {
        refresh: refreshToken,
      });
      localStorage.setItem("accessToken", response.data.access);
      store.commit("setIsLoggedIn", true);
    }
  } catch (error) {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    store.commit("setIsLoggedIn", false);
    location.reload();
  }
};
client.interceptors.request.use(async function (config) {
  // eslint-disable-next-line no-underscore-dangle
  let _config = config;
  let token = localStorage.getItem("accessToken");
  if (token) {
    if (jwtDecode(token).exp < moment().unix()) {
      await refreshToken();
    }
    _config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");
  }
  return _config;
});

export default client;
