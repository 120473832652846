<template>
  <hoverable>
    <template #default="{ hover }">
      <div class="space-y-0">
        <div
          class="mx-0 mt-0 flex flex-row justify-between rounded-lg text-sm md:-my-2 md:space-x-16 md:bg-transparent md:py-2"
          :class="{ 'bg-gray-200': hover }"
        >
          <a
            :href="href"
            class="block max-w-xs overflow-hidden truncate text-ellipsis whitespace-nowrap font-semibold"
          >
            {{ name }}
          </a>
          <div
            class="flex flex-row space-x-10 pl-4 text-gray-400"
            :class="{ 'text-gray-900': hover }"
          >
            <div class="flex flex-row space-x-1">
              <span>
                {{ numberOfSensors }}
              </span>
              <svg viewBox="0 0 20 20" class="h-5 w-5 fill-current">
                <path
                  stroke-width="0"
                  fill-rule="evenodd"
                  d="M17.778 8.222c-4.296-4.296-11.26-4.296-15.556 0A1 1 0 01.808 6.808c5.076-5.077 13.308-5.077 18.384 0a1 1 0 01-1.414 1.414zM14.95 11.05a7 7 0 00-9.9 0 1 1 0 01-1.414-1.414 9 9 0 0112.728 0 1 1 0 01-1.414 1.414zM12.12 13.88a3 3 0 00-4.242 0 1 1 0 01-1.415-1.415 5 5 0 017.072 0 1 1 0 01-1.415 1.415zM9 16a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </template>
  </hoverable>
</template>

<script>
import Hoverable from "../common/Hoverable.vue";

export default {
  name: "VenueLink",

  components: {
    Hoverable,
  },

  props: {
    href: {
      type: String,
      default: "#",
    },
    name: {
      type: String,
      required: true,
    },
    numberOfSensors: {
      type: Number,
      required: true,
    },
  },
};
</script>
