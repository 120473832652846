import * as d3 from "d3";
import moment from "moment";

export const arrayToObject = (arr, key) => {
  const entries = arr.map((obj) => [obj[key], obj]);
  return Object.fromEntries(entries);
};

export const sensorDataToCSV = (arr) => {
  let csv = "date,co2 (ppm),temperature (°C),humidity (%)\n";
  arr.forEach((obj) => {
    const values = obj["values"];
    const rowData = [
      moment(obj["date"]).format("YYYY-MM-DDTHH:mm:ssZ"),
      values["co2"],
      values["temperature"],
      values["humidity"],
    ];
    csv += `${rowData.join(",")}\n`;
  });
  return csv;
};

export const sensorDataToXLSX = (arr) => {
  let xlsx = [["date", "co2 (ppm)", "temperature (°C)", "humidity (%)"]];
  arr.forEach((obj) => {
    const values = obj["values"];
    const rowData = [
      [
        moment(obj["date"]).format("YYYY-MM-DDTHH:mm:ssZ"),
        values["co2"],
        values["temperature"],
        values["humidity"],
      ],
    ];
    xlsx = [...xlsx, ...rowData];
  });
  return xlsx;
};

export const translate = (dx = 0, dy = 0) => {
  return `translate(${dx},${dy})`;
};

/**
 * Creates and returns an SVG container using the outer dimensions.
 */
export const createSvg = (selector, width, height, margin) => {
  return d3
    .select(selector)
    .append("svg")
    .classed("mx-auto", true)
    .attr("viewBox", [0, 0, width, height])
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", translate(margin.left, margin.top));
};

/**
 * Returns the inner dimensions of the SVG container.
 */
export const getInnerSize = (width, height, margin) => {
  return {
    width: width - margin.left - margin.right,
    height: height - margin.top - margin.bottom,
  };
};

export const co2Levels = [600, 800, 1000, 1200];
export const temperatureLevels = [14, 17, 23, 26, 100];
export const humidityLevels = [30, 40, 60, 70, 100];

export const validateEmail = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const getFormattedDateChile = () => {
  const targetDay = new Date();
  const dateFormatter = new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "America/Santiago",
  });

  const formattedDate = dateFormatter
    .format(targetDay)
    .split("/")
    .reverse()
    .join("-");

  return formattedDate;
};

export const getFormattedDateUtc = () => {
  const targetDay = new Date();
  const dateFormatter = new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  });

  const formattedDate = dateFormatter
    .format(targetDay)
    .split("/")
    .reverse()
    .join("-");

  return formattedDate;
};
