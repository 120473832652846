<template>
  <section class="flex-1">
    <div class="h-full bg-gray-100">
      <div class="grid h-full grid-cols-1 text-center xl:mx-6 xl:grid-cols-7">
        <information-list />
        <dashboard />
      </div>
    </div>
  </section>
</template>

<script>
import Dashboard from "../components/dashboard/Dashboard.vue";
import InformationList from "../components/cards/InformationList.vue";

export default {
  components: {
    InformationList,
    Dashboard,
  },

  data() {
    return {
      interval: null,
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
