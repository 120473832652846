<template>
  <div
    :class="{ 'bg-gray-100': dark }"
    class="flex h-screen flex-col justify-between px-20 pt-20"
  >
    <div class="grid grid-cols-3 font-bold">
      <img
        class="h-8 self-start object-contain"
        src="../../assets/logo.png"
        alt="Flair Chile logo"
      />
      <p class="whitespace-nowrap text-xl">
        {{ description }}
      </p>
      <div />
    </div>
    <div class="p-2">
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-col justify-between">
          <p class="text-center text-xl">&nbsp;</p>
          <div class="text-left">
            <div class="flex">
              <p class="font-bold">Sensor:&nbsp;</p>
              <p>{{ sensor }}</p>
            </div>
            <div class="flex">
              <p class="font-bold">Sede:&nbsp;</p>
              <p>{{ venue }}</p>
            </div>
            <div class="flex">
              <p class="font-bold">Fecha:&nbsp;</p>
              <p>
                {{ from_.format("DD/MM/YYYY") }} al
                {{ to.format("DD/MM/YYYY") }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <p class="text-center text-xl">Score</p>
          <p
            class="rounded-lg border-2 bg-white px-8 py-1 text-center text-5xl font-bold"
          >
            {{ score }}
          </p>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center gap-4">
        <p class="mb-1 text-3xl font-bold" />
        <div class="w-full rounded-lg border-2 bg-white p-2">
          <p class="pt-4 text-lg font-bold text-gray-600">CO2</p>
          <time-line :time-line="timeLine" />
        </div>
        <heat-map-legend />
        <information-list sensor />
      </div>
    </div>
    <p>{{ page }}</p>
    <report-footer />
  </div>
</template>

<script>
import "moment/locale/es";
import HeatMapLegend from "../heatmap/Legend.vue";
import InformationList from "../cards/InformationList.vue";
import ReportFooter from "./ReportFooter.vue";
import TimeLine from "../heatmap/TimeLine.vue";
import { camelizeKeys } from "humps";
import moment from "moment";

export default {
  name: "ReportSensorIndex",

  components: {
    InformationList,
    TimeLine,
    HeatMapLegend,
    ReportFooter,
  },

  data() {
    return {
      to: null,
      venue: "",
      score: null,
      timeLine: {},
      sensor: "",
      from_: null,
      page: null,
      description: "",
      dark: false,
    };
  },

  async created() {
    const {
      dark,
      description,
      page,
      from,
      to,
      venue,
      venue_grid,
      sensor_info,
      sensor_infos,
      utc_offset,
      score,
      time_line,
      device_id,
      sensor,
    } = JSON.parse(localStorage.getItem("report-body"));
    this.page = page;
    this.dark = dark;
    this.from_ = moment(from).utcOffset(utc_offset);
    this.to = moment(to).utcOffset(utc_offset);
    this.venue = venue;
    this.score = score;
    this.sensor = sensor;
    this.description = description;
    this.$store.commit("setSensorInfo", camelizeKeys(sensor_info));
    this.$store.commit("setSensorInfos", camelizeKeys(sensor_infos));
    this.$store.commit("setVenueGrid", venue_grid);
    this.timeLine = time_line[device_id];
  },
};
</script>
