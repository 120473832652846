<template>
  <div
    v-if="loadingVenues"
    class="flex h-screen flex-col items-center justify-center"
  >
    <font-awesome-icon
      icon="spinner"
      class="h-12 w-12 animate-spin text-gray-600"
    />
  </div>
  <div v-else class="flex h-screen flex-col">
    <navbar />
    <general v-if="page === 'general'" class="grow" />
    <request-form v-if="page === 'reports'" class="grow" />
    <actuators-dashboard v-if="page === 'actuators'" class="grow" />
    <food-safety-general v-if="page === 'food-safety'" class="grow" />
    <page-footer />
    <feedback-button />
    <feedback-slide-over />
  </div>
</template>

<script>
import General from "./General.vue";
import Navbar from "../components/navbar/Navbar.vue";
import PageFooter from "../components/footer/PageFooter.vue";
import RequestForm from "../components/reports/Form.vue";
import ActuatorsDashboard from "../components/actuators/Dashboard.vue";
import FoodSafetyGeneral from "../components/food_safety/General.vue";
import FeedbackButton from "../components/feedback/FeedbackButton.vue";
import FeedbackSlideOver from "../components/feedback/FeedbackSlideOver.vue";
import { getFormattedDateUtc } from "../utils.js";

export default {
  name: "Logged",

  components: {
    ActuatorsDashboard,
    FoodSafetyGeneral,
    FeedbackButton,
    General,
    Navbar,
    PageFooter,
    RequestForm,
    FeedbackSlideOver,
  },

  data() {
    return {
      interval: null,
      timeout: null,
    };
  },

  computed: {
    loadingVenues() {
      return this.$store.state.loadingVenues;
    },
    page() {
      return this.$store.state.page;
    },
    venue() {
      return this.$store.state.currentVenue;
    },
  },
  watch: {
    venue: {
      deep: true,
      handler() {
        this.changeVenue();
      },
    },
  },

  async created() {
    await this.$store.dispatch("loadVenues");
    this.startPollVenueDataInterval();
  },

  async beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    async startPollVenueDataInterval() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(async () => {
        await this.pollVenueData();
      }, 120000);
    },

    async pollVenueData() {
      if (this.$store.state.actuatorUserUpdatePending) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(async () => {
          clearInterval(this.interval);
          await this.pollVenueData();
          this.startPollVenueDataInterval();
        }, 5000);
      } else {
        if (this.$store.state.user.canAccessActuators) {
          await this.$store.dispatch("loadVenueActuators");
          await this.$store.dispatch(
            "loadVenueActuatorsAlerts",
            this.$store.state
          );
        }
        await this.$store.dispatch("loadVenueGrid");
        await this.$store.dispatch("loadVenueInfo");
        const targetDay = getFormattedDateUtc();
        await this.$store.dispatch("getEnergyMetersData", {
          venueId: this.$store.state.currentVenue.id,
          target_day: targetDay,
        });
      }
    },
    async changeVenue() {
      clearInterval(this.interval);
      await this.pollVenueData();
      this.startPollVenueDataInterval();
    },
  },
};
</script>
