<template>
  <div class="flex h-screen flex-col justify-between px-20 pt-20">
    <div class="grid grid-cols-3 font-bold">
      <img
        class="h-8 self-start object-contain"
        src="../../assets/logo.png"
        alt="Flair Chile logo"
      />
    </div>
    <div class="p-2">
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-col justify-between">
          <p class="text-center text-xl">&nbsp;</p>
          <div class="text-left">
            <p class="font-bold">Resumen general</p>
            <div class="flex">
              <p class="font-bold">Sede:&nbsp;</p>
              <p>{{ venue }}</p>
            </div>
            <div class="flex">
              <p class="font-bold">Fecha:&nbsp;</p>
              <p>
                {{ from_.format("DD/MM/YYYY") }} al
                {{ to.format("DD/MM/YYYY") }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <p class="mb-1 text-xl">Score general</p>
          <p
            class="rounded-lg border-2 px-8 py-1 text-center text-5xl font-bold"
          >
            {{ score }}
          </p>
        </div>
      </div>
      <div class="flex flex-col justify-center gap-4">
        <p class="mb-1 text-3xl font-bold text-gray-600">Mapa de sensores</p>
        <heat-map />
        <heat-map-legend />
        <information-list />
      </div>
    </div>
    <p>1</p>
    <report-footer />
  </div>
</template>

<script>
import "moment/locale/es";
import HeatMap from "../heatmap/HeatMap.vue";
import HeatMapLegend from "../heatmap/Legend.vue";
import InformationList from "../cards/InformationList.vue";
import ReportFooter from "./ReportFooter.vue";
import { camelizeKeys } from "humps";
import moment from "moment";

export default {
  name: "ReportVenue",

  components: {
    InformationList,
    HeatMap,
    HeatMapLegend,
    ReportFooter,
  },

  data() {
    return {
      to: null,
      venue: "",
      score: null,
      from_: null,
    };
  },

  async created() {
    const {
      from,
      to,
      venue,
      venueGrid,
      venueInfo,
      venueInfos,
      utcOffset,
      score,
    } = camelizeKeys(JSON.parse(localStorage.getItem("report-body")));
    this.from_ = moment(from).utcOffset(utcOffset);
    this.to = moment(to).utcOffset(utcOffset);
    this.venue = venue;
    this.score = score;
    this.$store.commit("setVenueInfo", venueInfo);
    this.$store.commit("setVenueInfos", venueInfos);
    this.$store.commit("setVenueGrid", venueGrid);
  },
};
</script>
