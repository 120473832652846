<template>
  <div ref="alerts" class="bg-white" :style="{ height: combinedHeight + 'px' }">
    <div class="sticky top-0 z-10 bg-white">
      <div
        class="flex items-center justify-between border-b border-gray-300 p-5"
      >
        <h3 class="text-lg font-semibold text-gray-900">Alertas</h3>
      </div>

      <div class="border-b border-gray-300">
        <multiple-select
          :options="sensors"
          @update:selection="updateSelection"
        />
      </div>

      <div class="flex border-b border-gray-300">
        <button
          :class="{ 'border-b-2 border-blue-400': currentTab === 'new' }"
          class="flex-1 p-4 text-center text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
          @click="changeTab('new')"
        >
          Principal
        </button>
        <button
          :class="{ 'border-b-2 border-blue-400': currentTab === 'resolved' }"
          class="flex-1 p-4 text-center text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
          @click="changeTab('resolved')"
        >
          Resueltas
        </button>
        <button
          :class="{ 'border-b-2 border-blue-400': currentTab === 'discarded' }"
          class="flex-1 p-4 text-center text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none"
          @click="changeTab('discarded')"
        >
          Descartadas
        </button>
      </div>
    </div>

    <div class="h-[calc(100%-10rem)] overflow-y-auto">
      <div v-if="isLoading">
        <div class="flex items-center justify-center p-10">
          <font-awesome-icon
            icon="spinner"
            class="h-6 w-6 animate-spin text-gray-400"
          />
        </div>
      </div>

      <div v-else-if="alerts.length === 0">
        <div class="flex items-center justify-center p-10">
          <span class="text-sm text-gray-500"
            >No hay alertas en la última semana</span
          >
        </div>
      </div>

      <ul v-else class="divide-y divide-gray-200">
        <li
          v-for="(alert, index) in filteredAlerts"
          :key="index"
          class="relative flex h-28 flex-col justify-between"
        >
          <div class="flex items-center justify-between space-x-3 p-4">
            <span
              v-if="alert.correct === undefined || alert.correct === null"
              class="h-2 w-2 rounded-full bg-blue-500"
            ></span>
            <span
              :class="[
                alert.iconBackground,
                'flex h-8 w-8 items-center justify-center rounded-full',
              ]"
            >
              <font-awesome-icon
                :icon="alert.icon"
                class="h-5 w-5 text-white"
              />
            </span>
            <div class="min-w-0 flex-1">
              <p class="text-sm text-gray-500">{{ alert.text }}</p>
            </div>

            <div class="relative flex items-center">
              <template v-if="currentTab === 'new'">
                <button
                  type="button"
                  title="Confirmar que alerta es real"
                  class="flex items-center justify-center rounded-full border-2 border-green-500 bg-white p-1 text-green-500 shadow-sm hover:bg-green-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  style="width: 1.5rem; height: 1.5rem"
                  @click.stop="updateAlert(alert.id, true)"
                >
                  <font-awesome-icon
                    icon="check"
                    style="width: 0.75rem; height: 0.75rem"
                    aria-hidden="true"
                  />
                </button>
                <button
                  type="button"
                  title="Alerta fue un error o ya fue resuelta"
                  class="ml-2 flex items-center justify-center rounded-full border-2 border-red-500 bg-white p-1 text-red-500 shadow-sm hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  style="width: 1.5rem; height: 1.5rem"
                  @click.stop="updateAlert(alert.id, false)"
                >
                  <font-awesome-icon
                    icon="times"
                    style="width: 0.75rem; height: 0.75rem"
                    aria-hidden="true"
                  />
                </button>
              </template>
            </div>
          </div>
          <div class="mx-3 my-2 flex justify-around text-xs text-gray-500">
            <span>Desde: {{ alert.date }} {{ alert.time }}</span>
            <div class="flex items-center space-x-1">
              <span>Duración: {{ alert.duration }}</span>
            </div>
          </div>
        </li>
        <div class="border-b border-gray-300"></div>
      </ul>
    </div>
  </div>
</template>

<script>
import MultipleSelect from "../common/Multiselect.vue";

export default {
  name: "Alerts",
  components: {
    MultipleSelect,
  },
  data() {
    return {
      alerts: [],
      currentTab: "new",
      selectedActuatorsIds: [],
      isLoading: false,
    };
  },
  computed: {
    venueGrid() {
      return this.$store.state.venueGrid;
    },
    sensors() {
      return (
        this.venueGrid.sensors.filter(
          (sensor) => sensor.brand === "em300" || sensor.brand === "gs301"
        ) ?? []
      );
    },
    filteredAlerts() {
      return this.alerts.filter((alert) => {
        if (this.currentTab === "new") return alert.correct === null;
        if (this.currentTab === "resolved") return alert.correct === true;
        if (this.currentTab === "discarded") return alert.correct === false;
        return true;
      });
    },
    combinedHeight() {
      const gridHeight = 400; // Placeholder for actual grid height
      const statsInlineHeight = 50; // Placeholder for actual stats inline height
      const buffer = 33;
      return gridHeight + statsInlineHeight + buffer;
    },
  },
  methods: {
    updateSelection(selection) {
      this.selectedActuatorsIds = selection.map((sensor) => sensor.id);
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    updateAlert(id, confirmed) {
      const alertIndex = this.alerts.findIndex((alert) => alert.id === id);
      if (alertIndex !== -1) {
        this.$set(this.alerts, alertIndex, {
          ...this.alerts[alertIndex],
          correct: confirmed,
        });
      }
    },
  },
};
</script>
