<template>
  <footer>
    <div class="w-full border-t bg-footer text-flairblack">
      <div
        class="m-auto grid grid-cols-3 px-10 py-2 text-center text-sm text-gray-600 md:px-20 md:py-4"
      >
        <p
          class="col-span-2 col-start-1 mt-2 whitespace-nowrap font-bold md:col-span-1 md:col-start-2"
        >
          © Copyright 2023. Todos los derechos reservados.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
};
</script>
