<template>
  <div>
    <modal
      v-if="isModalVisible"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      @close-modal="closeModal"
      @in-modal="inModal"
      @toggle-config="changeModal"
    >
      <template #body>
        <div v-if="!loading" class="flex h-full w-full flex-col">
          <div class="mb-4 inline-flex justify-center">
            <p class="mr-2 text-3xl font-medium">
              {{ selectedSensor?.name }}
            </p>
          </div>

          <div class="flex h-full w-full flex-grow justify-center">
            <div class="flex h-full w-full flex-col">
              <div class="mt-3 flex justify-center">
                <date-picker
                  :disabled="loading"
                  class="z-10 flex justify-center"
                />
              </div>
              <measurement-charts
                :brand="selectedSensor?.brand"
                class="h-full w-full flex-grow"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex h-full min-h-[33rem] flex-col items-center justify-center"
        >
          <font-awesome-icon
            icon="spinner"
            class="h-12 w-12 animate-spin text-gray-600"
          />
        </div>
      </template>
    </modal>
    <modal
      v-if="isConfigModalVisible"
      modal-class="modal-config"
      @close-modal="closeConfigModal"
      @in-modal="inModal"
    >
      <template #header> Head </template>
      <template #body> Body </template>
      <template #footer> Footer </template>
    </modal>

    <div
      class="relative grid w-full grid-cols-4 gap-1 rounded-lg bg-white sm:grid-cols-6 lg:grid-cols-10"
    >
      <div
        v-for="sensor in filledSensors"
        :key="sensor.id"
        :class="[
          sensor.id == popOver ? 'bg-white' : sensorColor(sensor),
          'popOverCard flex aspect-square cursor-pointer items-center justify-center rounded-md text-[0.6rem] text-white',
          sensor.id == popOver ? 'popOverShow shadow-lg' : 'popOverHidden',
          sensor.fake && 'pointer-events-none',
        ]"
        @click="togglePopOver(sensor.id)"
      >
        <div
          v-if="sensor.id == popOver"
          class="flex h-full w-full flex-col items-center justify-between"
        >
          <div
            :class="[
              sensorBorder(sensor),
              sensorColor(sensor),
              'shadowed-header flex w-full flex-1 items-center gap-1 px-2 font-semibold',
            ]"
            @click="selectSensor(sensor)"
          >
            <span
              class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center font-semibold"
            >
              {{ sensor.name }}
            </span>
            <font-awesome-icon
              icon="ellipsis"
              class="h-3 w-3 transform transition-transform duration-300 hover:scale-110"
              transform="shrink-5"
              mask="circle"
            />
          </div>

          <div
            v-if="sensor.values"
            class="flex-2 flex w-full flex-col items-center justify-center bg-black bg-opacity-5"
          >
            <div class="flex flex-col items-start">
              <p
                v-if="sensor.brand === 'em300'"
                class="font-bold text-flairtem"
              >
                <font-awesome-icon icon="temperature-half" class="w-6" />
                {{ sensor.values.temperature }}°C
              </p>
              <p
                v-if="sensor.brand === 'em300'"
                class="font-bold text-flairhum"
              >
                <font-awesome-icon icon="droplet" class="w-6" />
                {{ sensor.values.humidity }}%
              </p>
              <p
                v-if="sensor.brand === 'gs301'"
                class="font-bold text-yellow-600"
              >
                <font-awesome-icon icon="toilet" class="w-6" />
                {{ sensor.values.nh3 }} ppm
              </p>
              <p
                v-if="sensor.brand === 'gs301'"
                class="font-bold text-green-700"
              >
                <font-awesome-icon icon="biohazard" class="w-6" />
                {{ sensor.values.h2s }} ppm
              </p>
              <template v-if="sensor.brand === 'ws523'">
                <p class="font-bold text-blue-600">
                  <font-awesome-icon icon="bolt" class="w-6" />
                  {{ sensor.values.voltage }} V
                </p>
                <p class="font-bold text-green-600">
                  <font-awesome-icon icon="plug" class="w-6" />
                  {{ sensor.values.power }} W
                </p>
                <p class="font-bold text-yellow-600">
                  <font-awesome-icon icon="tachometer-alt" class="w-6" />
                  {{ sensor.values.current }} mA
                </p>
              </template>
            </div>
          </div>
          <span
            v-if="sensor.values && isSuperuser && isToday"
            class="text-lastmeasure w-full bg-black bg-opacity-5 pb-1 text-gray-900"
          >
            {{ timeDistance(sensor.values.timestamp) }}
          </span>
          <div
            v-if="!sensor.values"
            class="flex-2 flex w-full flex-col items-center justify-center bg-black bg-opacity-5 py-1"
          >
            <p class="text-red text-xs">Sin datos</p>
          </div>
        </div>
        <div v-else class="px-2">
          <p class="font-bold">
            {{ sensor.name }}
          </p>
          <p class="text-base">
            {{ getSensorPreviewValue(sensor) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../dashboard/DatePicker.vue";
import Modal from "../cards/Modal.vue";
import MeasurementCharts from "./MeasurementsCharts.vue";
import { listenerMixin } from "../../mixins";
import moment from "moment";

export default {
  name: "HeatMap",
  components: {
    DatePicker,
    Modal,
    MeasurementCharts,
  },
  mixins: [listenerMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSensor: null,
      isModalVisible: false,
      isConfigModalVisible: false,
      popOver: "",
      inside: false,
      defaultColors: {
        low: "bg-blue-500", // Tailwind class for low temperature
        mediumLow: "bg-blue-300", // Tailwind class for medium-low temperature
        medium: "bg-yellow-400", // Tailwind class for medium temperature
        mediumHigh: "bg-orange-400", // Tailwind class for medium-high temperature
        high: "bg-red-300", // Tailwind class for high temperature
        default: "bg-gray-200", // Default color for missing data
      },
    };
  },

  computed: {
    isToday() {
      return this.$store.state.selectedDate.isSame(moment(), "day");
    },
    isSuperuser() {
      return this.$store.state.user.isSuperuser;
    },
    venueGrid() {
      return this.$store.state.venueGrid;
    },
    filledSensors() {
      if (!this.venueGrid) return [];
      return (
        this.venueGrid?.sensors?.filter(
          (sensor) =>
            sensor.brand === "em300" ||
            sensor.brand === "gs301" ||
            sensor.brand === "ws523"
        ) ?? []
      );
    },
  },

  methods: {
    timeDistance(time) {
      return this.capitalizeFirstLetter(moment(time).fromNow());
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    keyDownHandler(e) {
      if (e.key === "Escape") {
        this.closeModal();
        this.popOver = "";
      }
    },
    selectSensor(sensor) {
      this.selectedSensor = sensor;
      this.isModalVisible = true;
      this.$store.dispatch("selectSensor", { id: sensor.pk });
    },
    inModal() {
      this.inside = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedChart = 0;
      this.inside = false;
    },
    closeConfigModal() {
      this.isConfigModalVisible = false;
      this.inside = false;
    },
    sensorColor(sensor) {
      const brand = sensor.brand;

      if (brand === "gs301") {
        if (!sensor.values) return this.defaultColors.default;
        const nh3 = sensor.values?.nh3 ?? 0;
        const h2s = sensor.values?.h2s ?? 0;

        const nh3Normalized = Math.min(nh3 / 25, 1);
        const h2sNormalized = Math.min(h2s / 10, 1);

        const combinedScore = (nh3Normalized + h2sNormalized) / 2;

        if (combinedScore < 0.2) return "bg-green-500";
        if (combinedScore >= 0.2 && combinedScore < 0.5) return "bg-yellow-500";
        if (combinedScore >= 0.5 && combinedScore < 0.8) return "bg-orange-500";
        return "bg-red-500";
      }

      if (brand === "em300") {
        const temperature = sensor.values?.temperature;

        if (temperature == null) return this.defaultColors.default;
        if (temperature < -25) return "bg-blue-500";
        if (temperature >= -25 && temperature <= -15) return "bg-green-500";
        if (temperature > -15 && temperature <= -5) return "bg-yellow-500";
        return "bg-red-500";
      }
      if (brand === "ws523") {
        if (!sensor.values) return this.defaultColors.default;
        const current = sensor.values?.current ?? 0;

        if (current < 100) return "bg-green-500";
        if (current >= 100 && current < 500) return "bg-yellow-500";
        if (current >= 500 && current < 1000) return "bg-orange-500";
        return "bg-red-500";
      }

      return this.defaultColors.default;
    },
    sensorBorder(sensor) {
      return sensor.id == this.popOver
        ? "border-gray-500"
        : this.sensorColor(sensor);
    },
    togglePopOver(clickedSensor) {
      if (this.popOver == clickedSensor) {
        this.popOver = "";
      } else {
        this.popOver = clickedSensor;
      }
    },
    changeModal() {
      this.isModalVisible = false;
      this.isConfigModalVisible = true;
    },
    getSensorPreviewValue(sensor) {
      switch (sensor.brand) {
        case "em300":
          if (
            sensor.values?.temperature === undefined ||
            sensor.values?.temperature === null
          )
            return "";
          return sensor.values?.temperature + "º";
        case "gs301":
          if (sensor.values?.nh3 === undefined || sensor.values?.nh3 === null)
            return "";
          return sensor.values?.nh3 + "ppm";
        case "ws523":
          if (
            sensor.values?.current === undefined ||
            sensor.values?.current === null
          )
            return "";
          return sensor.values?.current + "mA";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.popOverShow {
  transform: scale(1.5);
  z-index: 2;
  overflow: hidden;
  border-width: 0px;
}
.popOverHidden {
  transform: scale(1);
  z-index: 0;
}

.text-lastmeasure {
  font-size: 0.5rem;
}
.text-flairtem,
.text-flairhum {
  font-size: 0.6rem;
}
.flex-2 {
  flex: 2;
}
</style>
