<template>
  <div class="w-full pb-12 text-gray-400">
    <div class="flex w-full items-center justify-center">
      <div class="flex w-full items-center justify-between">
        <div class="flex items-center gap-2">
          <font-awesome-icon icon="phone" />
          <p>+56 9 5679 0199</p>
        </div>
        <div class="flex items-center gap-2">
          <font-awesome-icon icon="envelope" />
          <p>contacto@goflair.cl</p>
        </div>
      </div>
    </div>
    <p>© 2023 Flair. Todos los derechos reservados.</p>
  </div>
</template>

<script>
export default {
  name: "ReportFooter",
};
</script>
