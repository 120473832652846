<template>
  <div>
    <font-awesome-icon :icon="icon" :class="['peer', extraClass]" />
    <span
      class="absolute z-10 ml-7 hidden w-48 rounded-xl border border-gray-300 bg-white p-3 text-sm font-normal text-black opacity-95 shadow-xl shadow-gray-400/50 peer-hover:block"
    >
      {{ msg }}
    </span>
  </div>
</template>
<script>
export default {
  name: "InfoToolTip",
  props: {
    msg: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "info",
    },
    extraClass: {
      type: String,
      default: "p-1 text-white rounded-full bg-gray-500 h-3 w-3 cursor-pointer",
    },
  },
};
</script>
