<template>
  <div class="space-y-8">
    <div class="flex justify-center">
      <div class="isolate inline-flex rounded-md shadow-sm">
        <button
          v-for="(day, idx) in daysOfWeek"
          :key="day"
          type="button"
          :class="buttonClasses(idx)"
          @click="toggleDay(day)"
        >
          {{ day.slice(0, 3) }}
        </button>
      </div>
    </div>
    <div class="grid grid-cols-3 items-center gap-4">
      <label for="frequency" class="text-sm font-medium text-gray-700">
        Repetir cada
      </label>
      <input
        id="frequency"
        v-model="localSettings.frequency"
        type="number"
        min="1"
        step="1"
        class="w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
      />
      <select
        v-model="localSettings.intervalUnit"
        class="w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
      >
        <option value="weeks">semana(s)</option>
        <option value="months">mes(es)</option>
      </select>
    </div>
    <div>
      <label for="endDate" class="block text-sm font-medium text-gray-700">
        Fecha de término
      </label>
      <input
        id="endDate"
        v-model="localSettings.endDate"
        type="date"
        :min="tomorrowDate"
        class="mt-1 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recurrentScheduleSettings: {
      type: Object,
      default: () => ({
        selectedDays: [],
        frequency: 1,
        intervalUnit: "weeks",
        endDate: this.tomorrowDate,
      }),
    },
  },
  emits: ["update:recurrent-schedule-settings"],
  data() {
    return {
      localSettings: {
        ...this.recurrentScheduleSettings,
      },
      daysOfWeek: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ],
    };
  },
  computed: {
    tomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().split("T")[0];
    },
  },
  watch: {
    recurrentScheduleSettings: {
      handler(newValue) {
        if (newValue === this.localSettings) return;
        this.localSettings = { ...newValue };
        this.$emit("update:recurrent-schedule-settings", this.localSettings);
      },
      deep: true,
    },
  },
  methods: {
    toggleDay(day) {
      const index = this.localSettings.selectedDays.indexOf(day);
      if (index >= 0) {
        this.localSettings.selectedDays.splice(index, 1);
      } else {
        this.localSettings.selectedDays.push(day);
      }
    },
    buttonClasses(idx) {
      return [
        "relative inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10",
        idx === 0 ? "rounded-l-md" : "-ml-px",
        idx === this.daysOfWeek.length - 1 ? "rounded-r-md" : "",
        this.localSettings.selectedDays.includes(this.daysOfWeek[idx])
          ? "bg-blue-600 text-white ring-blue-600 hover:bg-blue-700"
          : "bg-white hover:bg-gray-50",
      ];
    },
  },
};
</script>
