<template>
  <div class="mx-auto w-full max-w-4xl border-gray-500">
    <div class="mx-3 space-y-1">
      <form>
        <Combobox v-model="activeSelection" name="select" multiple>
          <div class="relative">
            <span class="inline-block w-full rounded-md shadow-sm">
              <div
                class="relative w-full cursor-default rounded-lg bg-white py-2 pl-2 pr-10 text-left transition duration-150 ease-in-out focus-within:border-blue-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-blue-500 sm:text-sm sm:leading-5"
              >
                <span class="flex flex-wrap gap-2">
                  <ComboboxInput
                    class="h-full w-full border-none p-0 focus:outline-none focus:ring-0"
                    placeholder="Filtrar..."
                    @input="query = $event.target.value"
                  />
                  <span
                    v-for="selection in activeSelection"
                    :key="selection.id"
                    class="flex items-center gap-1 rounded bg-blue-100 px-2 py-0.5 text-blue-800"
                  >
                    <span>{{ selection.name }}</span>
                    <svg
                      class="h-4 w-4 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      @click.stop.prevent="removeSelection(selection)"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </span>
                </span>
                <ComboboxButton
                  class="absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <svg
                    class="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      d="M5 8l5 5 5-5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ComboboxButton>
              </div>
            </span>

            <div class="absolute mt-1 w-full rounded-md bg-white shadow-lg">
              <ComboboxOptions
                class="shadow-xs max-h-60 overflow-auto rounded-md py-1 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5"
              >
                <ComboboxOption
                  v-for="option in filteredOptions"
                  :key="option.id"
                  v-slot="{ active, selected }"
                  :value="option"
                  as="template"
                >
                  <li
                    class="relative cursor-default select-none py-2 pl-3 pr-9 focus:outline-none"
                    :class="active ? 'bg-blue-500 text-white' : 'text-gray-900'"
                  >
                    <span
                      class="block truncate"
                      :class="{
                        'font-semibold': selected,
                        'font-normal': !selected,
                      }"
                    >
                      {{ option.name }}
                    </span>
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 right-0 flex items-center pr-4"
                      :class="{
                        'text-white': active,
                        'text-blue-500': !active,
                      }"
                    >
                      <svg
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </div>
          </div>
        </Combobox>
      </form>
    </div>
  </div>
</template>

<script>
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";

export default {
  components: {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    initialSelection: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:selection"],
  data() {
    return {
      query: "",
      activeSelection: this.initialSelection,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((opt) =>
        opt.name.toLowerCase().includes(this.query.toLowerCase())
      );
    },
  },
  watch: {
    activeSelection(newVal) {
      this.$emit("update:selection", newVal);
    },
  },
  methods: {
    removeSelection(opt) {
      this.activeSelection = this.activeSelection.filter((p) => p !== opt);
    },
  },
};
</script>
