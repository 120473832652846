<template>
  <div class="flex justify-center gap-2 sm:flex">
    <button class="mb-12 mt-8 hover:bg-gray-100" @click="leftChart">
      <font-awesome-icon
        icon="angle-left"
        class="h-4 w-4 text-gray-700 sm:m-2 sm:h-8 sm:w-8"
      />
    </button>
    <variable-chart class="w-full" v-bind="variables[selectedChart]" />
    <button class="mb-12 mt-8 hover:bg-gray-100" @click="rightChart">
      <font-awesome-icon
        icon="angle-right"
        class="m-2 h-4 w-4 text-gray-700 sm:h-8 sm:w-8"
      />
    </button>
  </div>
</template>
<script>
import VariableChart from "../cards/VariableChart.vue";

export default {
  name: "MeasurementsChart",
  components: {
    VariableChart,
  },
  props: {
    brand: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      em300Variables: [
        { variable: "temperature", humanized: "Temperatura", unit: "C°" },
        { variable: "humidity", humanized: "Humedad", unit: "%" },
      ],
      gs301Variables: [
        { variable: "nh3", humanized: "NH3", unit: "ppm" },
        { variable: "h2s", humanized: "H2S", unit: "ppm" },
      ],
      ws523Variables: [
        { variable: "voltage", humanized: "Voltaje", unit: "V" },
        { variable: "power", humanized: "Potencia", unit: "W" },
        { variable: "current", humanized: "Corriente", unit: "mA" },
        { variable: "energySum", humanized: "Energía Total", unit: "Wh" },
        { variable: "factor", humanized: "Factor de Potencia", unit: "" },
      ],
      selectedChart: 0,
    };
  },
  computed: {
    variables() {
      switch (this.brand) {
        case "em300":
          return this.em300Variables;
        case "gs301":
          return this.gs301Variables;
        case "ws523":
          return this.ws523Variables;
        default:
          return [];
      }
    },
  },
  watch: {
    brand: {
      immediate: true,
      handler() {
        this.selectedChart = 0;
      },
    },
  },
  methods: {
    leftChart() {
      if (this.selectedChart > 0) {
        this.selectedChart--;
      } else {
        this.selectedChart = this.variables.length - 1;
      }
    },
    rightChart() {
      if (this.selectedChart < this.variables.length - 1) {
        this.selectedChart++;
      } else {
        this.selectedChart = 0;
      }
    },
  },
};
</script>
