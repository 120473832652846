<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-lg"
            >
              <div class="sm:flex sm:items-start">
                <div class="flex w-full flex-col divide-y text-left">
                  <div class="flex items-center justify-between p-5">
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold text-gray-900"
                      >{{ actuatorName }}</DialogTitle
                    >
                    <button type="button" @click="closeModal">
                      <font-awesome-icon
                        icon="xmark"
                        class="h-6 w-6 text-gray-400 hover:text-gray-500"
                      />
                    </button>
                  </div>
                  <div class="max-h-96 w-full overflow-auto">
                    <div
                      v-if="timeline.length === 0"
                      class="my-6 flex items-center justify-center"
                    >
                      <font-awesome-icon
                        icon="spinner"
                        class="fa-spin h-6 w-6 text-gray-400"
                      />
                    </div>
                    <ul v-else role="list" class="p-5">
                      <li v-for="(event, eventIdx) in timeline" :key="eventIdx">
                        <div
                          class="relative"
                          :class="{ 'pb-6': eventIdx !== timeline.length - 1 }"
                        >
                          <span
                            v-if="eventIdx !== timeline.length - 1"
                            class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                          <div class="relative flex space-x-3">
                            <div>
                              <span
                                :class="[
                                  event.iconBackground,
                                  'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                                ]"
                              >
                                <font-awesome-icon
                                  :icon="event.icon"
                                  class="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div
                              class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5"
                            >
                              <div>
                                <p class="text-sm text-gray-500">
                                  {{ event.text }}
                                </p>
                                <p
                                  class="mt-1 text-xs font-light text-gray-400"
                                >
                                  {{ event.author }}
                                </p>
                              </div>
                              <div
                                class="flex flex-col whitespace-nowrap text-right text-sm text-gray-500"
                              >
                                <time>{{ event.date }}</time>
                                <time>{{ event.time }}</time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      timeline: [],
      isLoading: false,
    };
  },
  computed: {
    isOpen() {
      return this.$store.state.isActuatorLogsModalOpen;
    },
    actuatorName() {
      return this.$store.state.venueActuators.find(
        (actuator) => actuator.id === this.$store.state.selectedActuatorId
      ).name;
    },
    setpointPresets() {
      return this.$store.state.setpointPresets;
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.timeline = [];
        this.loadTimeline();
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("closeActuatorLogsModal");
    },
    async loadTimeline() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(
          "loadActuatorLogs",
          this.$store.state.selectedActuatorId
        );
        this.timeline = this.translateLogsToTimeline(
          this.$store.state.actuatorLogs
        );
      } catch (error) {
        console.error("Error loading timeline:", error);
      } finally {
        this.isLoading = false;
      }
    },
    translateLogsToTimeline(logs) {
      if (logs.length === 0) {
        return [
          {
            text: "No han habido cambios durante la última semana",
            date: "",
            time: "",
            icon: "file",
            iconBackground: "bg-blue-400",
            author: "",
          },
        ];
      }
      logs.sort((a, b) => b.timestamp.localeCompare(a.timestamp));

      return logs.map((log) => {
        const date = new Date(log.timestamp);
        const formattedDate = date.toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
        });
        const formattedTime = date.toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
        return {
          text: this.getTextFromLog(log),
          date: formattedDate,
          time: formattedTime,
          icon: this.getIconFromLog(log),
          iconBackground: this.getIconBackgroundFromLog(log),
          author:
            log.author != "Flair" && log.author
              ? `Modificado por ${log.author}`
              : "Cambio autónomo Flair",
        };
      });
    },
    getTextFromLog(log) {
      switch (log.keyChanged) {
        case "unit_power":
          return log.newValue ? "Equipo encendido" : "Equipo apagado";
        case "unit_mode":
          switch (log.newValue) {
            case "fan":
            case "ventilation":
              return "Equipo ventilando";
            case "heat":
            case "heating":
              return "Equipo calentando";
            case "cool":
            case "cooling":
              return "Equipo enfriando";
            case "off":
            case "stopped":
              return "Equipo apagado";
            default:
              return `Modo del equipo cambiado a ${log.newValue}`;
          }
        case "online":
          return log.newValue ? "Equipo conectado" : "Equipo desconectado";
        case "setpoint_temp_low":
          return `Límite inferior cambiado a ${log.newValue}ºC`;
        case "setpoint_temp_high":
          return `Límite superior cambiado a ${log.newValue}ºC`;
        case "setpoint_mode":
          switch (log.newValue) {
            case "manual":
              return "Modo de control manual";
            case "preset":
              return "Modo de control por preajustes";
            default:
              return "Modo de control cambiado";
          }
        case "current_setpoint_preset":
          if (this.setpointPresets[log.newValue]) {
            return `Preajuste de temperatura cambiado a ${
              this.setpointPresets[log.newValue].name
            }`;
          } else {
            return "Preajuste de temperatura cambiado";
          }
        case "automatic_control":
          return log.newValue
            ? "Control automático activado"
            : "Control automático desactivado";
        case "control_cycle_mode":
          return log.newValue == "eco"
            ? "Modo de ciclo de control cambiado a Flair Eco"
            : "Modo de ciclo de control cambiado a Flair";
        case "dynamic_cool_setpoint":
          return `Setpoint de enfriamiento ajustado a ${log.newValue}ºC`;
        case "dynamic_heat_setpoint":
          return `Setpoint de calentamiento ajustado a ${log.newValue}ºC`;
        case "target_setpoint":
          return `Temperatura objetivo ajustada a ${log.newValue}ºC`;
        default:
          return "Acción desconocida";
      }
    },
    getIconFromLog(log) {
      switch (log.keyChanged) {
        case "unit_power":
          return "power-off";
        case "unit_mode":
          switch (log.newValue) {
            case "fan":
              return "fan";
            case "heat":
              return "fire";
            case "cool":
              return "snowflake";
            case "off":
              return "power-off";
            default:
              return "question";
          }
        case "online":
          return log.newValue ? "plug-circle-check" : "plug-circle-xmark";
        case "setpoint_temp_low":
          return "temperature-arrow-down";
        case "setpoint_temp_high":
          return "temperature-arrow-up";
        case "setpoint_mode":
          switch (log.newValue) {
            case "manual":
              return "user-pen";
            case "preset":
              return "list-check";
            default:
              return "question";
          }
        case "current_setpoint_preset":
          return "temperature-half";
        case "automatic_control":
          return "cog";
        case "control_cycle_mode":
          return "leaf";
        case "dynamic_cool_setpoint":
          return "temperature-arrow-down";
        case "dynamic_heat_setpoint":
          return "temperature-arrow-up";
        case "target_setpoint":
          return "temperature-half";
        default:
          return "question";
      }
    },
    getIconBackgroundFromLog(log) {
      const colors = {
        success: "bg-emerald-400",
        warning: "bg-amber-400",
        info: "bg-blue-400",
        error: "bg-red-400",
        neutral: "bg-gray-400",
        highlight: "bg-violet-400",
      };

      switch (log.keyChanged) {
        case "unit_power":
          return log.newValue ? colors.success : colors.neutral;
        case "unit_mode":
          switch (log.newValue) {
            case "fan":
              return colors.success;
            case "heat":
              return colors.warning;
            case "cool":
              return colors.info;
            default:
              return colors.neutral;
          }
        case "online":
          return log.newValue ? colors.success : colors.error;
        case "setpoint_temp_low":
        case "setpoint_temp_high":
        case "setpoint_mode":
        case "current_setpoint_preset":
          return colors.highlight;
        case "automatic_control":
          return log.newValue ? colors.success : colors.neutral;
        case "control_cycle_mode":
          return log.newValue == "eco" ? colors.success : colors.neutral;
        case "dynamic_cool_setpoint":
          return colors.info;
        case "dynamic_heat_setpoint":
          return colors.warning;
        case "target_setpoint":
          return colors.highlight;
        default:
          return colors.neutral;
      }
    },
  },
};
</script>
