<template>
  <div
    class="grid h-full w-full items-center whitespace-nowrap bg-gray-100 text-center text-white"
  >
    <div class="flex w-full items-center justify-center">
      <div
        class="flex w-9/12 flex-col justify-center gap-4 self-center rounded-lg bg-white px-8 py-4 shadow-lg"
      >
        <div>
          <div class="flex w-full items-center justify-between py-2">
            <span class="font-semibold text-gray-600">1. Parámetros</span>
          </div>
          <div class="flex grid grid-cols-2 gap-4 text-sm">
            <div
              class="cursor-not-allowed rounded-lg bg-blue-500 px-4 py-1 text-white opacity-75"
            >
              <p>CO2</p>
            </div>
            <div
              class="transform rounded-lg px-4 py-1 transition duration-200"
              :class="
                additionalOption
                  ? 'cursor-pointer bg-blue-500 text-white'
                  : 'cursor-pointer rounded bg-gray-200 text-black'
              "
              @click="toggleFunction"
            >
              <p>Temperatura y Humedad</p>
            </div>
          </div>
        </div>
        <div>
          <div class="flex items-center py-2">
            <span class="font-semibold text-gray-600">2. Rango</span>
          </div>
          <div class="flex grid grid-cols-4 gap-2 text-sm">
            <div
              class="transform rounded-lg px-4 py-1 transition duration-200"
              :class="{
                'bg-blue-500': currentDays == 7,
                'cursor-pointer rounded bg-gray-200 text-black hover:bg-blue-500 hover:text-white':
                  currentDays != 7,
              }"
              @click="setIndex(0)"
            >
              <p>7 días</p>
            </div>
            <div
              class="transform rounded-lg px-4 py-1 transition duration-200"
              :class="{
                'bg-blue-500': currentDays == 14,
                'cursor-pointer rounded bg-gray-200 text-black hover:bg-blue-500 hover:text-white':
                  currentDays != 14,
              }"
              @click="setIndex(1)"
            >
              <p>14 días</p>
            </div>
            <div
              class="transform rounded-lg px-4 py-1 transition duration-200"
              :class="{
                'bg-blue-500': currentDays == 30,
                'cursor-pointer rounded bg-gray-200 text-black hover:bg-blue-500 hover:text-white':
                  currentDays != 30,
              }"
              @click="setIndex(2)"
            >
              <p>30 días</p>
            </div>
            <datepicker
              v-model="customRange"
              :clearable="false"
              :enable-time-picker="false"
              :auto-position="false"
              auto-apply
              range
              :max-range="isSuperuser ? null : 30"
              format="dd/MM/yyyy"
            >
              <template #trigger>
                <div
                  class="transform rounded-lg px-4 py-1 transition duration-200"
                  :class="{
                    'bg-blue-500': Boolean(customRange),
                    'cursor-pointer rounded bg-gray-200 text-black hover:bg-blue-500 hover:text-white':
                      !customRange,
                  }"
                >
                  <span class="flex flex-row justify-center space-x-5">
                    <p v-if="customRange">
                      {{ formattedDate[0] }} - {{ formattedDate[1] }}
                    </p>
                    <p v-else>Personalizado</p>
                  </span>
                </div>
              </template>
            </datepicker>
          </div>
        </div>
        <div>
          <div class="flex w-full items-center justify-between py-2">
            <span class="font-semibold text-gray-600"
              >3. Selecciona los espacios</span
            >
          </div>
          <div class="flex justify-end gap-2 pb-2">
            <button
              class="flex w-auto rounded-md bg-gray-200 px-3 text-sm text-black hover:bg-gray-300"
              @click="unSelectAll"
            >
              <p>Deseleccionar todos</p>
            </button>
            <button
              class="flex w-auto items-center rounded-md bg-blue-500 px-3 text-sm text-white hover:bg-blue-600"
              @click="selectAll"
            >
              <p>Seleccionar todos</p>
            </button>
          </div>
          <report-grid class="font-medium text-black" />
        </div>
        <div class="flex w-fit items-end justify-center gap-2 self-end">
          <tri-state-button
            label="Generar reporte"
            :funct="submit"
            icon="envelope"
            :state="buttonState"
            extra-classes="text-white font-bold py-2 px-4 rounded-lg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import ReportGrid from "./ReportGrid.vue";
import TriStateButton from "../common/TriStateButton.vue";
import { buttonMixin } from "../../mixins";
import moment from "moment";

export default {
  name: "ReportForm",
  components: {
    Datepicker,
    ReportGrid,
    TriStateButton,
  },
  mixins: [buttonMixin],

  data() {
    return {
      additionalOption: false,
      loading: false,
      days: [7, 14, 30],
      daysIndex: 1,
      sent: false,
      buttonState: "default",
      customRange: undefined,
      custom: false,
    };
  },

  computed: {
    isSuperuser() {
      return this.$store.state.user.isSuperuser;
    },
    payload() {
      const { additionalOption, customRange, daysIndex, reportSensors } = this;
      return { additionalOption, customRange, daysIndex, reportSensors };
    },
    currentVenue() {
      return this.$store.state.currentVenue;
    },
    currentDays() {
      return this.days[this.daysIndex];
    },
    reportSensors() {
      return this.$store.state.reportSensors;
    },
    formattedDate() {
      return [
        moment(this.customRange[0]).format("DD/MM/yyyy"),
        moment(this.customRange[1]).format("DD/MM/yyyy"),
      ];
    },
  },

  watch: {
    daysIndex: {
      deep: true,
      handler(value) {
        if (value) {
          this.customRange = undefined;
        }
      },
    },
    customRange: {
      deep: true,
      handler() {
        if (this.customRange) {
          this.daysIndex = undefined;
        }
      },
    },
  },

  methods: {
    unSelectAll() {
      this.$store.commit("setReportSensors", {});
    },
    selectAll() {
      this.$store.commit(
        "setReportSensors",
        this.$store.state.venueGrid.sensors.reduce(
          (obj, item) => ({ ...obj, [item.pk]: true }),
          {}
        )
      );
    },
    toggleFunction() {
      this.additionalOption = !this.additionalOption;
    },
    setIndex(index) {
      this.daysIndex = index;
    },
    async submit() {
      this.loading = true;
      let date = moment();
      let selectedSensors = Object.keys(this.reportSensors).filter(
        (k) => this.reportSensors[k] == true
      );
      if (this.daysIndex >= 0) {
        date.subtract(this.currentDays, "days");
        this.$store
          .dispatch("requestReport", {
            venue_id: this.currentVenue.id,
            body: {
              from: date.toISOString(),
              utcOffset: date.utcOffset(),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              selectedSensors,
              extra: this.additionalOption,
            },
          })
          .then(() => {
            this.buttonState = "success";
            this.sent = true;
          })
          .catch(() => {
            this.buttonState = "error";
          });
      } else {
        this.$store
          .dispatch("requestReport", {
            venue_id: this.currentVenue.id,
            body: {
              from: moment(this.customRange[0]).toISOString(),
              to: moment(this.customRange[1]).toISOString(),
              utcOffset: date.utcOffset(),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              selectedSensors,
              extra: this.additionalOption,
            },
          })
          .then(() => {
            this.buttonState = "success";
            this.sent = true;
          })
          .catch(() => {
            this.buttonState = "error";
          });
      }
      this.loading = false;
    },
  },
};
</script>
