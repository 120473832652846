<template>
  <div class="flex flex-col justify-center px-10 py-4">
    <div class="flex w-full justify-center">
      <div class="days w-1/6">
        <p v-for="dayOfWeek in daysOfWeek" :key="dayOfWeek.key">
          {{ dayOfWeek.label }}
        </p>
      </div>
      <div class="contributions relative w-5/6 rounded-lg bg-white">
        <div
          v-for="{ dayOfWeek, hourOfDay } in hourDayMatrix"
          :key="dayOfWeek.key"
          class="popOverCard"
          :style="{
            borderColor: gradientBorder(hourOfDay, dayOfWeek),
            background: gradientColor(hourOfDay, dayOfWeek),
          }"
        >
          <p class="font-bold text-white">
            {{ alt ? gravitySign(hourOfDay, dayOfWeek) : "" }}
          </p>
        </div>
      </div>
    </div>
    <div class="hours ml-1/6 pt-2">
      <p v-for="hourOfDay in hoursOfDay" :key="hourOfDay.key">
        {{ hourOfDay.label }}
      </p>
    </div>
  </div>
</template>
<script>
import { co2Levels, humidityLevels, temperatureLevels } from "../../utils";
import { listenerMixin } from "../../mixins";

export default {
  name: "HeatMap",
  components: {},
  mixins: [listenerMixin],
  props: {
    alt: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    timeLine: {
      type: Object,
      default: () => {},
    },
    variable: {
      type: String,
      default: "co2",
    },
  },
  data() {
    return {
      popOver: "",
      daysOfWeek: [
        { key: "Monday", label: "Lunes" },
        { key: "Tuesday", label: "Martes" },
        { key: "Wednesday", label: "Miércoles" },
        { key: "Thursday", label: "Jueves" },
        { key: "Friday", label: "Viernes" },
        { key: "Saturday", label: "Sábado" },
        { key: "Sunday", label: "Domingo" },
      ],
      hoursOfDay: [
        { key: 8, label: "08:00" },
        { key: 9, label: "09:00" },
        { key: 10, label: "10:00" },
        { key: 11, label: "11:00" },
        { key: 12, label: "12:00" },
        { key: 13, label: "13:00" },
        { key: 14, label: "14:00" },
        { key: 15, label: "15:00" },
        { key: 16, label: "16:00" },
        { key: 17, label: "17:00" },
        { key: 18, label: "18:00" },
        { key: 19, label: "19:00" },
        { key: 20, label: "20:00" },
      ],
      defaultColors: [
        "rgb(228,228,231)",
        this.colors.heatblue,
        this.colors.heatlightblue,
        this.colors.heatyellow,
        this.colors.heatlightred,
        this.colors.heatred,
      ],
      altColors: [
        "rgb(228,228,231)",
        "rgb(30, 58, 138)",
        "rgb(59, 130, 246)",
        "rgb(191, 219, 254)",
        "rgb(59, 130, 246)",
        "rgb(30, 58, 138)",
      ],
    };
  },

  computed: {
    functionChooser() {
      switch (this.variable) {
        case "co2":
          return this.co2GravityChooser;
        case "temperature":
          return this.temperatureGravityChooser;
        case "humidity":
          return this.humidityGravityChooser;
        default:
          return null;
      }
    },
    colors() {
      return this.alt ? this.altColors : this.defaultColors;
    },
    hourDayMatrix() {
      return this.daysOfWeek.reduce(
        (result, dayOfWeek) => [
          ...result,
          ...this.hoursOfDay.map((hourOfDay) => ({
            dayOfWeek,
            hourOfDay,
          })),
        ],
        []
      );
    },
  },

  methods: {
    gravitySign(hour, day) {
      if (!(day.key in this.timeLine) || !(hour.key in this.timeLine[day.key]))
        return "";
      switch (this.functionChooser(this.timeLine[day.key][hour.key])) {
        case 1:
          return "-";
        case 2:
          return "-";
        case 4:
          return "+";
        case 5:
          return "+";
        default:
          return "";
      }
    },
    co2GravityChooser({ co2 }) {
      const range = co2Levels.findIndex((bound) => bound > co2);
      return range === -1 ? 5 : range;
    },
    temperatureGravityChooser({ temperature }) {
      const range = temperatureLevels.findIndex((bound) => bound > temperature);
      return range === -1 ? 0 : range + 1;
    },
    humidityGravityChooser({ humidity }) {
      const range = humidityLevels.findIndex((bound) => bound > humidity);
      return range === -1 ? 0 : range + 1;
    },
    gradientColor(hour, day) {
      if (!(day.key in this.timeLine) || !(hour.key in this.timeLine[day.key]))
        return this.colors[0];
      return this.colors[
        this.functionChooser(this.timeLine[day.key][hour.key])
      ];
    },
    gradientBorder() {
      return `rgb(200,200,210)`;
    },
  },
};
</script>
<style scoped>
.contributions {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(13, calc(100% / 13 - 1px));
  grid-template-rows: repeat(7, calc(100% / 7) - 1px);
  gap: 1px;
}

.days {
  height: auto;
  display: grid;
  grid-template-rows: repeat(7, calc(100% / 7));
}

.days p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-small;
}

.hours {
  height: auto;
  display: grid;
  grid-template-columns: repeat(13, auto);
  grid-column: span 13;
}

.hours p {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-55deg);
  font-size: x-small;
  margin-top: 4px;
}

.popOverCard {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  aspect-ratio: 4/3;
}
</style>
