export const buttonMixin = {
  data() {
    return {
      buttonState: "default",
    };
  },
  methods: {
    resetButton() {
      this.buttonState = "default";
    },
  },
  watch: {
    payload: {
      deep: true,
      handler: "resetButton",
    },
  },
};

export const showMixin = {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    close() {
      this.show = false;
    },
    open() {
      this.show = true;
    },
  },
};

export const listenerMixin = {
  mounted() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
};
