<template>
  <div class="contributions grid w-full grid-flow-dense gap-1.5 bg-white">
    <div
      v-for="sensor in filledSensors"
      :key="sensor.pk"
      class="popOverCard flex aspect-[5/3] transform justify-center rounded text-xs transition duration-200"
      :class="{
        'hover: cursor-pointer': sensor.pk,
        'pointer-events-none bg-gray-50 shadow-inner': sensor.fake,
        'border-t-8 border-gray-200 bg-blue-500 text-white shadow-inner':
          reportSensors[sensor.pk],
        'border border-gray-200 bg-gray-300 shadow':
          !reportSensors[sensor.pk] && sensor.pk && !sensor.fake,
      }"
      @click="selectSensor(sensor.pk)"
    >
      <div class="self-center">
        <p>
          {{ sensor.name }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReportGrid",

  computed: {
    venue() {
      return this.$store.state.currentVenue;
    },
    venueGrid() {
      return this.$store.state.venueGrid;
    },
    reportSensors() {
      return this.$store.state.reportSensors;
    },
    filledSensors() {
      let grid = this.venueGrid.sensors ?? [];
      if (grid.length < 40) {
        const fillers = Array.from({ length: 40 - grid.length }, () => ({
          values: [{}],
          name: "",
          pk: Math.random(),
          fake: true,
        }));
        grid = grid.concat(fillers);
      }
      return grid;
    },
  },
  watch: {
    venueGrid: {
      deep: true,
      handler() {
        this.$store.commit("setReportSensors", {});
        this.selectSensor(this.filledSensors[0].pk);
      },
    },
  },
  mounted() {
    this.$store.commit("setReportSensors", {});
    this.selectSensor(this.filledSensors[0].pk);
  },
  methods: {
    selectSensor(sensor) {
      const newReportSensors = { ...this.reportSensors };
      newReportSensors[sensor] = !newReportSensors[sensor];
      this.$store.commit("setReportSensors", newReportSensors);
    },
  },
};
</script>
<style scoped>
.contributions {
  grid-template-columns: repeat(10, calc((100% - 5px * 9) / 10));
  grid-template-rows: repeat(4, auto);
}

/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-100%);
  }
}
</style>
