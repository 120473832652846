<template>
  <div class="mb-3 mt-3 w-full">
    <div class="flex h-6 w-full justify-center overflow-hidden rounded-full">
      <div
        class="legend flex h-full w-full items-center justify-center overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700"
      >
        <div
          v-for="level in levels"
          :key="level.label"
          class="flex h-full w-full items-center justify-center bg-gray-300 text-center text-base font-semibold leading-none"
          :class="[level.color, { hidden: brief && !level.brief }]"
        >
          {{ level.label }}
        </div>
      </div>
    </div>
    <div
      class="text-sensortitle flex w-full justify-evenly font-semibold text-gray-600"
    >
      <div>
        <p>600ppm</p>
      </div>
      <div>
        <p>800ppm</p>
      </div>
      <div>
        <p>1000ppm</p>
      </div>
      <div>
        <p>1200ppm</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeatMapLegend",
  props: {
    brief: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      levels: [
        { color: "bg-heatblue text-white", label: "Saludable", brief: true },
        { color: "bg-heatlightblue text-white", label: "Bien" },
        { color: "bg-heatyellow text-black", label: "Precaución", brief: true },
        { color: "bg-heatlightred text-white", label: "Alerta" },
        { color: "bg-heatred text-white", label: "Crítico", brief: true },
      ],
    };
  },
};
</script>
