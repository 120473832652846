<template>
  <div class="h-screen">
    <login-form />
  </div>
</template>

<script>
import LoginForm from "../components/login/Form.vue";

export default {
  name: "Login",

  components: {
    LoginForm,
  },
};
</script>
