<template>
  <button
    :class="[
      extraClasses,
      stateClasses[state],
      disabled && 'pointer-events-none cursor-not-allowed bg-opacity-50',
      !squared && 'rounded',
    ]"
    @click="funct"
  >
    <span class="flex flex-row justify-center space-x-2">
      <p>{{ label }}</p>
      <font-awesome-icon :icon="buttonIcon" class="my-auto h-4 w-4" />
    </span>
  </button>
</template>
<script>
export default {
  name: "TriStateButton",
  props: {
    funct: {
      type: Function,
      required: true,
    },
    state: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["default", "success", "error"].indexOf(value) !== -1;
      },
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "arrow-right",
    },
    squared: {
      type: Boolean,
      default: false,
    },
    extraClasses: {
      type: String,
      default: "text-white font-bold my-3 py-2 px-4 w-auto",
    },
  },
  data() {
    return {
      stateClasses: {
        default: "bg-flairblue hover:bg-blue-700 cursor-pointer",
        success: "cursor-default bg-emerald-200 pointer-events-none",
        error: "bg-red-500",
      },
    };
  },
  computed: {
    buttonIcon() {
      let bIcon = this.icon;
      switch (this.state) {
        case "error":
          bIcon = "redo";
          break;
        case "success":
          bIcon = "check";
          break;
      }
      return bIcon;
    },
  },
};
</script>
