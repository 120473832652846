import client from "./client";

export default {
  retrieve: (id, from, to) =>
    client.get(`venues/${id}/venue_info?from=${from}&to=${to}`, {
      validateStatus: false,
    }),
  list: (id, from, to) =>
    client.get(`venues/${id}/venue_infos?from=${from}&to=${to}`, {
      validateStatus: false,
    }),
};
