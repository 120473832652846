<template>
  <section
    id="dashboard"
    class="col-span-5 mx-3 h-fit flex-1 self-center rounded-lg bg-white px-4 py-12 shadow-lg sm:px-12"
  >
    <div class="flex flex-col justify-around pt-2 md:flex-row md:pt-5">
      <date-picker :disabled="loading" class="flex justify-center" />
    </div>
    <div class="grid text-left">
      <span class="mt-6 text-gray-600"
        >Actualizado a las {{ lastUpdatedSensors }}</span
      >
    </div>
    <heat-map class="rounded-lg bg-white py-2" :loading="loading" />
    <heat-map-legend />
  </section>
</template>

<script>
import DatePicker from "./DatePicker.vue";
import HeatMap from "../heatmap/HeatMap.vue";
import HeatMapLegend from "../heatmap/Legend.vue";
import _ from "lodash";
import { listenerMixin } from "../../mixins";

export default {
  name: "Dashboard",

  components: {
    DatePicker,
    HeatMap,
    HeatMapLegend,
  },
  mixins: [listenerMixin],

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    sensor() {
      return this.$store.state.currentSensor;
    },
    data() {
      const rawData = this.$store.getters.sensorQueryDataArray(this.sensor);
      return _.filter(
        rawData,
        ({ values }) =>
          values.co2 != null &&
          values.temperature != null &&
          values.humidity != null
      );
    },
    selectedDate() {
      return this.$store.state.selectedDate;
    },
    lastUpdatedSensors() {
      return this.$store.state.lastUpdatedSensors;
    },
  },

  watch: {
    sensor: {
      deep: true,
      handler: "changeSensor",
    },
    selectedDate: {
      deep: true,
      handler: "changeQueryData",
    },
  },

  methods: {
    async changeSensor() {
      this.loading = true;
      if (this.sensor) {
        await this.$store.dispatch("loadSensorConfiguration", {
          sensor: this.sensor,
        });
      }
      this.loading = false;
    },
    async changeQueryData() {
      this.loading = true;
      if (this.sensor) {
        await this.$store.dispatch("loadSensorConfiguration", {
          sensor: this.sensor,
        });
        await this.$store.dispatch("loadSensorQueryData", {
          sensor: this.sensor,
        });
      }
      await this.$store.dispatch("loadVenueGrid");
      this.$store.dispatch("loadVenueInfo");
      this.loading = false;
    },
  },
};
</script>
