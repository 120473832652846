<template>
  <div>
    <modal
      v-if="isModalVisible"
      @close-modal="closeModal"
      @in-modal="inModal"
      @toggle-config="changeModal"
    >
      <template #body>
        <div v-if="!loading">
          <div class="inline-flex">
            <p class="mr-2 text-3xl font-medium">
              {{ selectedSensor?.name }}
            </p>
          </div>

          <div class="flex min-h-[30rem] justify-center">
            <div>
              <div class="mt-3 flex justify-center">
                <date-picker
                  :disabled="loading"
                  class="z-10 flex justify-center"
                />
              </div>
              <information-list sensor />
              <measurement-charts />
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex h-full min-h-[33rem] flex-col items-center justify-center"
        >
          <font-awesome-icon
            icon="spinner"
            class="h-12 w-12 animate-spin text-gray-600"
          />
        </div>
      </template>
    </modal>
    <modal
      v-if="isConfigModalVisible"
      modal-class="modal-config"
      @close-modal="closeConfigModal"
      @in-modal="inModal"
    >
      <template #header> Head </template>
      <template #body> Body </template>
      <template #footer> Footer </template>
    </modal>

    <div
      class="relative grid w-full grid-cols-4 gap-1 rounded-lg bg-white sm:grid-cols-6 lg:grid-cols-10"
    >
      <div
        v-for="sensor in filledSensors"
        :key="sensor.id"
        :style="{
          borderColor: sensorBorder(sensor),
          background: sensorColor(sensor),
        }"
        class="popOverCard flex aspect-square cursor-pointer items-center justify-center rounded-md text-[0.6rem]"
        :class="[
          sensor.id == popOver ? 'popOverShow shadow-lg' : 'popOverHidden',
          sensor.fake && 'pointer-events-none',
        ]"
        @click="togglePopOver(sensor.id)"
      >
        <div
          v-if="sensor.id == popOver"
          class="flex h-full w-full flex-col items-center justify-between"
        >
          <div
            :style="{ background: sensorBorder(sensor) }"
            class="shadowed-header flex w-full flex-1 items-center gap-1 bg-black bg-opacity-10 px-2 font-semibold"
            @click="selectSensor(sensor)"
          >
            <span
              class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center font-semibold"
              :class="co2TextChooser(sensor)"
            >
              {{ sensor.name }}
            </span>
            <font-awesome-icon
              icon="ellipsis"
              :class="co2TextChooser(sensor)"
              class="h-3 w-3 transform transition-transform duration-300 hover:scale-110"
              transform="shrink-5"
              mask="circle"
            />
          </div>

          <div
            v-if="sensor.values"
            class="flex-2 flex w-full flex-col items-center justify-center bg-black bg-opacity-5"
          >
            <div class="flex flex-col items-start">
              <p class="font-bold text-flairppm">
                <font-awesome-icon icon="smog" class="w-6" />
                {{ sensor.values.co2 }} ppm
              </p>
              <p class="font-bold text-flairtem">
                <font-awesome-icon icon="temperature-half" class="w-6" />
                {{ sensor.values.temperature }}°C
              </p>
              <p class="font-bold text-flairhum">
                <font-awesome-icon icon="droplet" class="w-6" />
                {{ sensor.values.humidity }}%
              </p>
            </div>
          </div>
          <span
            v-if="sensor.values && isSuperuser && isToday"
            class="text-lastmeasure w-full bg-black bg-opacity-5 pb-1 text-gray-900"
          >
            {{ timeDistance(sensor.values.timestamp) }}
          </span>
          <div
            v-if="!sensor.values"
            class="flex-2 flex w-full flex-col items-center justify-center bg-black bg-opacity-5 py-1"
          >
            <p class="text-red text-xs">Sin datos</p>
          </div>
        </div>
        <div v-else class="px-2">
          <p class="font-bold" :class="co2TextChooser(sensor)">
            {{ sensor.name }}
          </p>
          <p class="text-base" :class="co2TextChooser(sensor)">
            {{ getSensorPreviewValue(sensor) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "../dashboard/DatePicker.vue";
import InformationList from "../cards/InformationList.vue";
import MeasurementCharts from "./MeasurementsCharts.vue";
import Modal from "../cards/Modal.vue";
import { co2Levels } from "../../utils";
import { listenerMixin } from "../../mixins";
import moment from "moment";

export default {
  name: "HeatMap",
  components: {
    DatePicker,
    Modal,
    MeasurementCharts,
    InformationList,
  },
  mixins: [listenerMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSensor: null,
      isModalVisible: false,
      isConfigModalVisible: false,
      popOver: "",
      inside: false,
      defaultColors: [
        this.colors.heatblue,
        this.colors.heatlightblue,
        this.colors.heatyellow,
        this.colors.heatlightred,
        this.colors.heatred,
        "rgb(228,228,231)",
      ],
    };
  },

  computed: {
    isToday() {
      return this.$store.state.selectedDate.isSame(moment(), "day");
    },
    isSuperuser() {
      return this.$store.state.user.isSuperuser;
    },
    venueGrid() {
      return this.$store.state.venueGrid;
    },
    filledSensors() {
      let grid =
        this.venueGrid?.sensors?.filter(
          (sensor) => sensor.brand == "milesight"
        ) ?? [];
      if (grid.length < 40) {
        const fillers = Array.from({ length: 40 - grid.length }, () => ({
          values: [],
          name: "",
          id: Math.random(),
          fake: true,
        }));
        grid = grid.concat(fillers);
      }
      return grid;
    },
  },

  methods: {
    timeDistance(time) {
      return this.capitalizeFirstLetter(moment(time).fromNow());
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    co2ColorChooser(co2, options) {
      if (co2 == null) return options[options.length - 1];
      const range = co2Levels.findIndex((bound) => bound > co2);
      return range === -1 ? options[options.length - 2] : options[range];
    },
    co2TextChooser(sensor) {
      const co2 = sensor.values?.co2;
      0;
      if (!co2 || (co2 < co2Levels[2] && co2 >= co2Levels[1]))
        return "text-black";
      return "text-white";
    },
    keyDownHandler(e) {
      if (e.key === "Escape") {
        this.closeModal();
        this.popOver = "";
      }
    },
    selectSensor(sensor) {
      this.selectedSensor = sensor;
      this.isModalVisible = true;
      this.$store.dispatch("selectSensor", { id: sensor.pk });
    },
    inModal() {
      this.inside = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedChart = 0;
      this.inside = false;
    },
    closeConfigModal() {
      this.isConfigModalVisible = false;
      this.inside = false;
    },
    sensorColor(sensor) {
      const { id, values, fake } = sensor;

      if (fake) {
        return this.defaultColors[5];
      }
      if (id == this.popOver) {
        return `rgb(255,255,255)`;
      }
      return this.co2ColorChooser(values?.co2, this.defaultColors);
    },
    sensorBorder(sensor) {
      const { id, values } = sensor;
      if (id !== this.popOver) {
        return `rgb(200,200,210)`;
      }
      return this.co2ColorChooser(values?.co2, this.defaultColors);
    },
    togglePopOver(clickedSensor) {
      if (this.popOver == clickedSensor) {
        this.popOver = "";
      } else {
        this.popOver = clickedSensor;
      }
    },
    changeModal() {
      this.isModalVisible = false;
      this.isConfigModalVisible = true;
    },
    getSensorPreviewValue(sensor) {
      switch (this.$store.state.user.sensorPreview) {
        case "temperature":
          if (
            sensor.values?.temperature === undefined ||
            sensor.values?.temperature === null
          )
            return "";
          return sensor.values?.temperature + "º";
        case "humidity":
          if (
            sensor.values?.humidity === undefined ||
            sensor.values?.humidity === null
          )
            return "";
          return sensor.values?.humidity + "%";
        case "co2":
          return sensor.values?.co2 ?? "";
        default:
          return "";
      }
    },
  },
};
</script>
<style scoped>
.popOverShow {
  transform: scale(1.9);
  z-index: 2;
  overflow: hidden;
  border-width: 0px;
}
.popOverHidden {
  transform: scale(1);
  z-index: 0;
}

.text-lastmeasure {
  font-size: 0.5rem;
}
.text-flairtem,
.text-flairhum,
.text-flairppm {
  font-size: 0.6rem;
}
.flex-2 {
  flex: 2;
}
</style>
