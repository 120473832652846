import client from "./client";

export default {
  create: (payload) => client.post("users", payload),
  retrieve: (id) => client.get(`users/${id}`),
  reset: (email) => client.post("password_reset/", { email }),
  change_password: ({ oldPassword, newPassword }) =>
    client.put("change_password", {
      old_password: oldPassword,
      new_password: newPassword,
    }),
};
