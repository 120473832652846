<template>
  <section class="flex h-full flex-col items-center bg-gray-100 lg:flex-row">
    <div class="flex w-full flex-col lg:w-auto">
      <aside
        class="mx-4 flex w-full flex-col overflow-y-scroll rounded-lg bg-white shadow lg:mx-10 lg:w-96"
      >
        <Alerts />
      </aside>
    </div>
    <div
      class="mt-4 w-full justify-center text-center lg:mt-0 xl:mx-6 xl:grid-cols-7"
    >
      <Dashboard />
    </div>
  </section>
</template>

<script>
import Dashboard from "./Dashboard.vue";
import Alerts from "./Alerts.vue";

export default {
  components: {
    Dashboard,
    Alerts,
  },
  data() {
    return {
      interval: null,
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
