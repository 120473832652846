import { createRouter, createWebHistory } from "vue-router";
import Main from "../Main.vue";
import ReportSensorExtra from "../components/reports/ReportSensorExtra.vue";
import ReportSensorIndex from "../components/reports/ReportSensorIndex.vue";
import ReportVenue from "../components/reports/ReportVenue.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Main,
  },
  {
    path: "/report-sensor",
    name: "report-sensor",
    component: ReportSensorIndex,
  },
  {
    path: "/report-sensor-extra",
    name: "report-sensor-extra",
    component: ReportSensorExtra,
  },
  {
    path: "/report-venue",
    name: "report-venue",
    component: ReportVenue,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
