<template>
  <div data-cy="items-dropdown" class="relative">
    <div class="flex flex-row items-center rounded-lg text-sm font-semibold">
      <div
        class="flex h-8 w-8 items-center justify-center rounded-lg"
        :class="{ 'bg-flairblue text-white': show }"
        @mouseover="open"
        @mouseleave="close"
      >
        <font-awesome-icon icon="cog" class="h-6 w-6" />
      </div>
    </div>
    <div
      v-show="show"
      class="absolute right-0 z-10 w-max rounded-md md:pt-2"
      @mouseover="open"
      @mouseleave="close"
    >
      <div
        class="rounded-xl border-gray-300 bg-white px-2 py-2 shadow-md md:border-2 md:border-solid"
      >
        <slot :toggleOpen="toggleShow" />
      </div>
    </div>
  </div>
</template>

<script>
import { showMixin } from "../../mixins";
export default {
  name: "ItemsDropdown",
  mixins: [showMixin],

  props: {
    fontAwesomeIcontext: {
      type: String,
      default: "",
    },
  },
};
</script>
