<template>
  <nav
    class="sticky top-0 z-30 flex items-center justify-between border-b bg-white p-3 px-6 shadow"
  >
    <change-password
      v-if="isChangePassModalVisible"
      @close-modal="closeChangePassModal"
    />
    <add-user v-if="isAddUserModalVisible" @close-modal="closeAddUserModal" />
    <emergency-shutdown-modal
      :is-open="isEmergencyShutdownModalVisible"
      @close="closeEmergencyShutdownModal"
      @confirm="executeEmergencyShutdown"
    />
    <div class="flex items-center gap-6">
      <img
        class="h-6 object-contain"
        src="../../assets/logo.png"
        alt="Flair Chile logo"
      />
      <navbar-dropdown :text="currentVenue.name">
        <template #default="{ toggleShow, searchTerm }">
          <venue-link
            v-for="venue in filteredVenues(venues, searchTerm)"
            :key="venue.id"
            v-bind="venue"
            class="mt-2 block rounded-lg bg-transparent px-4 py-1 text-sm font-semibold md:mt-0 md:py-2"
            @click="[toggleShow(), $store.dispatch('selectVenue', venue)]"
          />
        </template>
      </navbar-dropdown>
    </div>
    <div class="hidden gap-3 md:flex">
      <navbar-link
        v-if="canAccessActuators"
        page-id="actuators"
        text="Actuadores"
        icon="temperature-half"
        @click="() => $store.commit('setPage', 'actuators')"
      />
      <navbar-link
        page-id="general"
        text="Sensores"
        icon="tachometer-alt"
        @click="() => $store.commit('setPage', 'general')"
      />
      <navbar-link
        v-if="canAccessTestView"
        page-id="food-safety"
        text="Test"
        icon="flask"
        @click="() => $store.commit('setPage', 'food-safety')"
      />
    </div>
    <div class="flex flex-row-reverse gap-3">
      <items-dropdown>
        <template #default="itemsDropdownProps">
          <div class="flex flex-col" @click="[itemsDropdownProps.toggleShow]">
            <link-items
              v-if="isSuperuser || isManager"
              data-cy="add-user"
              text="Nuevo usuario"
              icon="user"
              color="blue"
              @click="openAddUserModal"
            />
            <link-items
              text="Cambiar contraseña"
              icon="users-cog"
              color="blue"
              @click="openChangePassModal"
            />
            <link-items
              text="Apagado de emergencia"
              icon="power-off"
              color="red"
              @click="openEmergencyShutdownModal"
            />
            <link-items
              text="Cerrar sesión"
              icon="sign-out-alt"
              color="blue"
              @click="logout"
            />
          </div>
        </template>
      </items-dropdown>
      <p
        class="hidden gap-3 self-center whitespace-nowrap text-sm font-semibold md:flex"
      >
        <font-awesome-icon v-if="isSuperuser" icon="crown" class="h-5 w-5" />
        {{ userMail }}
      </p>
      <button class="md:hidden" @click="toggleMobileMenu">
        <font-awesome-icon
          icon="bars"
          class="flex h-7 w-7 items-center justify-center rounded-lg"
          :class="{ 'text-blue-500': isMobileMenuOpen }"
        />
      </button>
    </div>
    <div
      v-if="isMobileMenuOpen"
      class="absolute left-0 right-0 top-full border-b bg-white shadow-md md:hidden"
    >
      <navbar-link
        v-if="canAccessActuators"
        page-id="actuators"
        text="Actuadores"
        icon="temperature-half"
        @click="setPageAndCloseMobileMenu('actuators')"
      />
      <navbar-link
        page-id="general"
        text="Sensores"
        icon="tachometer-alt"
        @click="setPageAndCloseMobileMenu('general')"
      />
      <navbar-link
        v-if="canAccessTestView"
        page-id="food-safety"
        text="Test"
        icon="flask"
        @click="setPageAndCloseMobileMenu('food-safety')"
      />
    </div>
  </nav>
</template>

<script>
import AddUser from "./AddUser.vue";
import ChangePassword from "./ChangePassword.vue";
import ItemsDropdown from "./ItemsDropdown.vue";
import LinkItems from "./LinkItems.vue";
import NavbarDropdown from "./Dropdown.vue";
import NavbarLink from "./Link.vue";
import VenueLink from "./VenueLink.vue";
import { listenerMixin } from "../../mixins";
import EmergencyShutdownModal from "./EmergencyShutdownModal.vue";

export default {
  name: "Navbar",

  components: {
    AddUser,
    ChangePassword,
    NavbarLink,
    LinkItems,
    NavbarDropdown,
    ItemsDropdown,
    VenueLink,
    EmergencyShutdownModal,
  },
  mixins: [listenerMixin],
  data() {
    return {
      adminSiteUrl: `${process.env.VUE_APP_API_BASE_URL}/admin`,
      isChangePassModalVisible: false,
      isAddUserModalVisible: false,
      isEmergencyShutdownModalVisible: false,
      isMobileMenuOpen: false,
    };
  },

  computed: {
    venues() {
      return this.$store.state.venues;
    },
    currentVenue() {
      return this.$store.state.currentVenue;
    },
    userMail() {
      return this.$store.state.user.email;
    },
    isSuperuser() {
      return this.$store.state.user.isSuperuser;
    },
    isManager() {
      return this.$store.state.user.isManager;
    },
    canAccessActuators() {
      return this.$store.state.user.canAccessActuators;
    },
    canAccessTestView() {
      return this.$store.state.user.canAccessTestView;
    },
  },

  methods: {
    filteredVenues(venues, searchTerm) {
      if (!searchTerm) return venues;
      return venues.filter((venue) =>
        venue.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    logout() {
      this.$store.dispatch("logout");
    },
    openChangePassModal() {
      this.isChangePassModalVisible = true;
    },
    closeChangePassModal() {
      this.isChangePassModalVisible = false;
    },
    openAddUserModal() {
      this.isAddUserModalVisible = true;
    },
    closeAddUserModal() {
      this.isAddUserModalVisible = false;
    },
    openEmergencyShutdownModal() {
      this.isEmergencyShutdownModalVisible = true;
    },
    closeEmergencyShutdownModal() {
      this.isEmergencyShutdownModalVisible = false;
    },
    executeEmergencyShutdown() {
      this.$store.dispatch("emergencyShutdown");
    },
    keyDownHandler(e) {
      if (e.key === "Escape") {
        this.closeChangePassModal();
        this.closeAddUserModal();
        this.closeEmergencyShutdownModal();
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    setPageAndCloseMobileMenu(page) {
      this.$store.commit("setPage", page);
      this.isMobileMenuOpen = false;
    },
  },
};
</script>
