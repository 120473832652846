<template>
  <hoverable>
    <template #default="{ hover }">
      <a :href="href">
        <div
          class="mt-2 rounded-lg bg-white px-4 py-2 text-sm font-medium text-flairblack md:mt-0 md:bg-white"
          :class="{
            'md:bg-gray-200 md:text-black': hover || active,
            'cursor-default': active,
          }"
        >
          <div class="flex justify-between gap-4">
            <p>
              {{ text }}
            </p>
            <font-awesome-icon
              :icon="icon"
              class="h-4 w-4"
              :class="iconColor"
            />
          </div>
        </div>
      </a>
    </template>
  </hoverable>
</template>

<script>
import Hoverable from "../common/Hoverable.vue";

export default {
  name: "LinkItems",

  components: {
    Hoverable,
  },

  props: {
    pageId: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "#",
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "",
    },
  },

  computed: {
    currentPage() {
      return this.$store.state.page;
    },
    active() {
      return this.pageId === this.currentPage;
    },
    iconColor() {
      return {
        "text-red-500": this.color === "red",
        "text-blue-600": this.color === "blue",
        "": this.color === "",
      };
    },
  },
};
</script>
