<template>
  <button
    type="button"
    class="fixed bottom-5 right-5 inline-flex items-center gap-x-2 rounded-lg bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
    @click="openFeedbackSlideOver"
  >
    <font-awesome-icon icon="comment-dots" class="h-6 w-6" />
    Feedback
  </button>
</template>

<script>
export default {
  name: "FeedbackButton",
  methods: {
    openFeedbackSlideOver() {
      this.$store.commit("openFeedbackSlideOver");
    },
  },
};
</script>
