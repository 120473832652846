<template>
  <div class="flex flex-col items-center justify-center px-4 py-6">
    <div class="text-center">
      <div class="flex items-center text-center text-gray-900">
        <button
          type="button"
          class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          @click="navigateMonth('prev')"
        >
          <span class="sr-only">Previous month</span>
          <font-awesome-icon
            icon="angle-left"
            class="h-5 w-5"
            aria-hidden="true"
          />
        </button>
        <div class="flex-auto text-sm font-semibold">
          {{ formattedMonthYear }}
        </div>
        <button
          type="button"
          class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          @click="navigateMonth('next')"
        >
          <span class="sr-only">Next month</span>
          <font-awesome-icon
            icon="angle-right"
            class="h-5 w-5"
            aria-hidden="true"
          />
        </button>
      </div>
      <div
        class="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500"
      >
        <div>L</div>
        <div>M</div>
        <div>W</div>
        <div>J</div>
        <div>V</div>
        <div>S</div>
        <div>D</div>
      </div>
      <div
        class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200"
      >
        <button
          v-for="(day, dayIdx) in days"
          :key="dayIdx"
          type="button"
          :class="buttonClass(day, dayIdx)"
          @click="day.day ? selectDay(day) : null"
        >
          <time
            v-if="day.day"
            :datetime="day.date"
            :class="[
              'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
              day.isSelected && day.isToday && 'bg-blue-800',
              day.isSelected && !day.isToday && 'bg-blue-600',
            ]"
            >{{ day.day }}</time
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedDates: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:selected-dates"],
  data() {
    const now = new Date();
    return {
      currentMonth: now.getMonth(),
      currentYear: now.getFullYear(),
      days: [],
    };
  },
  computed: {
    formattedMonthYear() {
      const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      return `${months[this.currentMonth]} ${this.currentYear}`;
    },
  },
  watch: {
    selectedDates: {
      handler() {
        this.updateDays();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.updateDays();
  },
  methods: {
    navigateMonth(direction) {
      if (direction === "next") {
        if (this.currentMonth === 11) {
          this.currentMonth = 0;
          this.currentYear++;
        } else {
          this.currentMonth++;
        }
      } else {
        if (this.currentMonth === 0) {
          this.currentMonth = 11;
          this.currentYear--;
        } else {
          this.currentMonth--;
        }
      }
      this.updateDays();
    },
    updateDays() {
      const daysInMonth = new Date(
        this.currentYear,
        this.currentMonth + 1,
        0
      ).getDate();
      let startDayOfWeek = new Date(
        this.currentYear,
        this.currentMonth,
        1
      ).getDay();
      startDayOfWeek = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;
      const days = [];

      for (let i = 0; i < startDayOfWeek; i++) {
        days.push({ day: null });
      }

      for (let day = 1; day <= daysInMonth; day++) {
        const date = `${this.currentYear}-${String(
          this.currentMonth + 1
        ).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
        days.push({
          day,
          date,
          isCurrentMonth: true,
          isSelected: this.selectedDates.includes(date),
          isToday: date === new Date().toISOString().split("T")[0],
        });
      }

      this.days = days;
    },

    selectDay(selectedDay) {
      const index = this.selectedDates.indexOf(selectedDay.date);
      let newSelectedDates = [...this.selectedDates];
      if (index >= 0) {
        newSelectedDates.splice(index, 1);
      } else {
        newSelectedDates.push(selectedDay.date);
      }
      this.$emit("update:selected-dates", newSelectedDates);
    },
    buttonClass(day, dayIdx) {
      const totalDays = this.days.length;
      const lastRowFirstIdx = totalDays - (totalDays % 7 || 7);

      return [
        "p-1.5 hover:bg-gray-100 focus:z-10",
        day.isCurrentMonth
          ? "bg-white"
          : "bg-gray-200 hover:bg-gray-200 cursor-default",
        (day.isSelected || day.isToday) && "font-semibold",
        day.isSelected && "text-white",
        !day.isSelected &&
          day.isCurrentMonth &&
          !day.isToday &&
          "text-gray-900",
        !day.isSelected &&
          !day.isCurrentMonth &&
          !day.isToday &&
          "text-gray-400",
        day.isToday && !day.isSelected && "text-blue-600",
        dayIdx === 0 && "rounded-tl-lg",
        dayIdx === 6 && "rounded-tr-lg",
        dayIdx === lastRowFirstIdx && "rounded-bl-lg",
        dayIdx === totalDays - 1 && "rounded-br-lg",
      ];
    },
  },
};
</script>
